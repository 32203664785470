import { Row } from 'antd';
import styled from 'styled-components';

interface WidthProps {
  width: number | null;
}

interface ContainerProps {
  color: string;
}

export const Wrapper = styled.div`
  /* flex: 1; */
  margin: 0 4px;
`;

export const Header = styled.div`
  min-height: 70px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: white;
  padding: 10px;
  display: flex;
`;

export const Footer = styled.div`
  min-height: 96px;
  width: 100%;
  padding: 8px;
  padding-bottom: 16px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  background-color: ${props => props.theme.corPrimaria};
`;

export const FooterContent = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-left: 4px;
  display: flex;
  flex-direction: column;
`;

export const LabelSecureCode = styled.span`
  color: #ffffff;
  font-size: 14px;
`;

export const SecureCode = styled.span`
  color: #ffffff;
  font-size: 11px;
`;

export const ProductImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 8px;
  border-width: 2px;
  border-color: #ffffff;
  margin-right: 4px;
`;

export const FooterInfos = styled(Row)`
  background-color: #ffffff;
  min-height: 40px;
  width: 100%;
  border-radius: 14px;
  margin-bottom: 4px;
  padding: 10px;
`;

export const HeaderTitle = styled.span`
  color: ${props => props.theme.corPrimaria};
  font-size: 16px;
  text-align: center;
  flex: 1;
`;

export const Container = styled.div`
  flex: 1;
`;

export const Image = styled.img`
  border-radius: 14px;
  border-radius: 14px;
  margin: 0 4px;
  width: 100%;
`;

export const DezenaContainer = styled.div<WidthProps>`
  flex-direction: row;
  width: ${props => props.width && props.width}px;
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
  text-align: center;
`;

export const Dezena = styled.div<WidthProps>`
  width: ${props => props.width && props.width / 5}px;
  border-width: 1px;
  border-color: ${props => props.theme.corTextoSecundaria};
  justify-content: center;
  align-items: center;
  padding: 4px;
`;

export const DezenaText = styled.span`
  width: 40px;
  height: 40px;
  padding: 10px;
  margin: 2px 4px;
  color: ${props => props.theme.corPrimaria};
  text-align: center;
  font-weight: bold;
`;

export const ContainerDateTime = styled.div`
  border-color: ${props => props.theme.corTextoSecundaria};
  border-width: 2px;
  padding: 6px;
  border-radius: 8px;
  flex: 0.3;
`;

export const ContainerDateTimeRow = styled.div<ContainerProps>`
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 100%;
`;

export const LabelDateTime = styled.span`
  color: ${props => props.theme.corTextoSecundaria};
  font-size: 12px;
`;

export const ValueDateTime = styled.span`
  color: ${props => props.theme.corPrimaria};
  font-size: 14px;
`;

export const ContainerIcon = styled.div<ContainerProps>`
  background-color: ${props => props.color};
  border-radius: 17px;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
export const ContainerDateTimeText = styled.div`
  margin-left: 4px;
  display: flex;
  flex-direction: column;
`;
export const ContainerRegulation = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
export const ContainerText = styled.button`
  border-width: 2px;
  border-radius: 20px;
  border-color: ${props => props.theme.corTextoSecundaria};
  padding: 6px;
  justify-content: center;
  align-items: center;
  width: 240px;
  flex-direction: row;
  margin-bottom: 20px;
`;

export const ContainerTextMoreOne = styled.button`
  border-radius: 20px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  width: 240px;
  flex-direction: row;
  background-color: ${props => props.theme.corPrimaria};
  margin-top: 20px;
`;

export const ButtonText = styled.span`
  color: ${props => props.theme.corSecundaria};
  font-size: 14px;
  margin-left: 10px;
`;
