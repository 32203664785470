import * as React from 'react';

import { ProfileContainer } from './styles';
import { useAuth } from 'hooks/auth';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import NoImage from 'assets/perfil-padrao-masculino.png';

export const Profile = () => {
  const { user } = useAuth();
  const theme = useTheme();

  return (
    <ProfileContainer>
      <div style={{ padding: 30 }}>
        {user && user.urlImageProfile ? (
          <img src={user.urlImageProfile} alt={user.Nome} />
        ) : (
          <img src={NoImage} alt="Avatar" />
        )}
        <div style={{ marginTop: 10 }}>
          {user && (
            <Link to="/userdata">
              <strong style={{ color: theme.corPrimaria }}>{user.Nome}</strong>
            </Link>
          )}
        </div>
      </div>
    </ProfileContainer>
  );
};
