import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

export const Divider = styled.div`
  height: 2px;
  width: ${window.screen.width - 20}px;
  border-top-width: 2px;
  border-top-color: ${props => props.theme.corPrimaria};
  align-self: center;
`;

export const Title = styled.span`
  font-family: 'NeutraText-Bold';
  font-size: 20px;

  text-align: center;
`;

export const TitleContainer = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 10px;
`;

export const Image = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border-width: 2px;
  border-color: #ffffff;
  margin-right: 4px;
`;
