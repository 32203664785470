import { Empty } from 'antd';
import { ProdutoType } from 'model/Produto';
import React from 'react';
import { useLocation } from 'react-router-dom';

import TicketList from './components/TicketList';
import { Container, Divider, Title, TitleContainer, Image } from './styles';

const Tickets: React.FC<any> = () => {
  const location = useLocation<any>();
  const { product }: { product: ProdutoType } = location.state;
  return product ? (
    <>
      <TitleContainer>
        {!!product.icone && <Image src={product.icone} />}
        <Title>{product.titulo}</Title>
      </TitleContainer>
      <Divider />
      <Container>
        <TicketList product={product} />
      </Container>
    </>
  ) : (
    <Empty description="O Produto não foi informado" />
  );
};

export default Tickets;
