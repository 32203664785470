import React, { useState } from 'react';
import { Layout, Drawer } from 'antd';
import { FiMenu } from 'react-icons/fi';

import { Container, Main } from './styles';

import { useConfig } from 'hooks/config';
import { SideMenu } from 'components/SideMenu';
const { Header, Footer } = Layout;

const LayoutDefault: React.FC = ({ children }) => {
  const { product } = useConfig();
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Container>
      <Layout>
        <Drawer
          title={<h3 className="no-wrap-text">{product?.nome}</h3>}
          footer={<div style={{ textAlign: 'center' }}>Versão 1.0.0b</div>}
          placement="left"
          closable={true}
          onClose={onClose}
          visible={visible}
        >
          <SideMenu onClose={onClose} />
        </Drawer>

        <Layout className="site-layout">
          <Header
            className="header-background"
            style={{
              position: 'fixed',
              zIndex: 2,
              width: '100%',
              padding: 0,
            }}
          >
            <FiMenu
              className="trigger"
              color={product?.config?.web?.corPrimaria}
              onClick={showDrawer}
              size={30}
            />
            <img
              className="logo"
              height="50px"
              src={product?.icone}
              alt="Logo"
            />
          </Header>
          <Main style={{ marginTop: 80 }}>{children}</Main>
        </Layout>
        <Footer style={{ textAlign: 'center' }}>Idea Maker</Footer>
      </Layout>
    </Container>
  );
};

export default LayoutDefault;
