import styled from 'styled-components';

export const Data = styled.div`
  background-color: ${props => props.theme.corPrimaria};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
`;

export const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;
