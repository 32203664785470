/* eslint-disable no-template-curly-in-string */
import {
  Button,
  Divider,
  Form,
  Input,
  Layout,
  notification,
  Radio,
  Typography,
} from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { RadioChangeEvent } from 'antd/lib/radio';
import { LeftOutlined } from '@ant-design/icons';
import { useAuth } from 'hooks/auth';
import React, {
  ChangeEvent,
  createRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { RiLockPasswordLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import api from 'services/api';
import { useTheme } from 'styled-components';
import { hideCelular, hideEmail, onlyNumbers } from 'utils/StringHelper';
import { FormInstance } from 'antd/lib/form';

import { Container } from './styles';
import md5 from 'md5';
const { Content } = Layout;

interface RecoveryType {
  email: string;
  celular: string;
}

interface ValidateCodeType {
  valido: boolean;
  expirado: boolean;
}

const validateMessages = {
  required: '${label} é obrigatório!',
  types: {
    number: '${label} não é um número válido!',
  },
  senhaAtual: {
    pattern: '${label} a senha dever conter 4 números!',
  },
};

const ForgotPassword: React.FC = () => {
  const theme = useTheme();
  const { person, signIn } = useAuth();
  const history = useHistory();
  const formRef = createRef<FormInstance>();

  const [emailSMS, setEmailSMS] = useState();
  const [loading, setLoading] = useState(false);
  const [recovery, setRecovery] = useState<RecoveryType>();
  const [step, setStep] = useState(1);
  const [code, setCode] = useState<string>();
  const [newPassword, setNewPassword] = useState('');

  const onChange = (e: RadioChangeEvent) => {
    setEmailSMS(e.target.value);
  };

  useEffect(() => {
    if (!person) {
      history.replace('/login');
    }
  }, [person, history]);

  const handleSubmit = async (): Promise<void> => {
    if (emailSMS && !loading) {
      setLoading(true);
      const data = { cpf: person?.cpf, celular: false, email: false };
      if (emailSMS === 'email') data.email = true;
      if (emailSMS === 'sms') data.celular = true;
      api
        .post('/auth/esqueci', data)
        .then(response => {
          if (response && response.data && response.data.email) {
            setRecovery(response.data);
            setStep(2);
          }
        })
        .catch(error => {
          notification.error({
            message: 'Atenção 😬',
            description: error.response.data.mensagem,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSubmitCode = async (): Promise<void> => {
    if (code && code.length === 4 && !loading) {
      setLoading(true);
      const data = { cpf: person?.cpf, codigo: code };
      api
        .post('/auth/validarCodigo', data)
        .then(response => {
          if (response && response.data) {
            const validate: ValidateCodeType = response.data;
            if (!validate.valido) {
              notification.error({
                message: 'Atenção 😬',
                description: 'O código informado não é válido',
              });
            }
            if (validate.expirado) {
              notification.error({
                message: 'Atenção 😬',
                description: 'O código informado expirou',
              });
            }
            if (validate.valido && !validate.expirado) {
              setStep(prev => prev + 1);
            }
          }
        })
        .catch(error => {
          if (error && error.response && error.response.data) {
            notification.error({
              message: 'Atenção 😬',
              description:
                error.response.data.mensagem ||
                'Algo deu errado, tente novamente mais tarde.',
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleLogin = useCallback(
    async (senha: string): Promise<void> => {
      if (person) {
        await signIn({
          cpf: onlyNumbers(person.cpf),
          senha: senha,
        });
      }
    },
    [person, signIn],
  );

  const handleSubmitPassword = async (values: any): Promise<void> => {
    if (
      values.novaSenha &&
      values.novaSenha.length === 4 &&
      values.confirmacaoNovaSenha &&
      values.confirmacaoNovaSenha.length === 4 &&
      !loading
    ) {
      setLoading(true);
      const data = {
        key: { cpf: person?.cpf, codigo: code },
        nova: md5(values.novaSenha),
        confirmacao: md5(values.confirmacaoNovaSenha),
      };

      api
        .post('/auth/redefinir', data)
        .then(response => {
          if (response && response.data) {
            notification.success({
              message: 'Atenção 😬',
              description:
                response.data.mensagem || 'Senha alterada com sucesso.',
            });
            handleLogin(values.novaSenha);
          }
        })
        .catch(error => {
          if (error && error.response && error.response.data) {
            notification.error({
              message: 'Atenção 😬',
              description:
                error.response.data.mensagem ||
                'Algo deu errado, tente novamente mais tarde.',
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleNewPassword = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setNewPassword(value);
  };

  const checkPasswords = (rule: any, value: any) => {
    if ((newPassword && value === newPassword) || !newPassword) {
      return Promise.resolve();
    }
    return Promise.reject('As senhas não conferem');
  };

  return (
    <Container>
      <Content
        style={{
          textAlign: 'center',
          padding: 40,
          backgroundColor: 'white',
          marginTop: 40,
          borderRadius: 10,
        }}
      >
        <RiLockPasswordLine size={60} color={theme.corPrimaria} />
        {/* Requisitar código */}
        <div hidden={step !== 1}>
          <Typography.Title
            style={{
              textTransform: 'uppercase',
              fontSize: 20,
              marginBottom: 20,
            }}
          >
            Esqueceu sua senha ?
          </Typography.Title>
          <Typography.Paragraph>
            Não tem problema, redefina usando:
          </Typography.Paragraph>
          <Radio.Group
            style={{ width: '100%' }}
            onChange={onChange}
            value={emailSMS}
          >
            <Radio value="email">Email</Radio>
            <Divider />
            <Radio value="sms">SMS</Radio>
          </Radio.Group>
          <Button
            type="primary"
            size="large"
            style={{
              width: '100%',
              marginTop: 30,
            }}
            shape="round"
            disabled={!emailSMS}
            onClick={handleSubmit}
          >
            <b>Redefinir</b>
          </Button>
        </div>

        {/* Digitar código */}
        <div hidden={step !== 2}>
          <div>
            <Typography.Title
              style={{
                textTransform: 'uppercase',
                fontSize: 20,
                marginBottom: 20,
              }}
            >
              Código de Verificação
            </Typography.Title>
            {emailSMS === 'email' && recovery && (
              <Typography.Paragraph>
                Você receberá um código de verificação no seu email cadastrado
                <Typography.Text style={{ color: theme.corPrimaria }}>
                  {' '}
                  {hideEmail(recovery.email)}
                </Typography.Text>
              </Typography.Paragraph>
            )}
            {emailSMS === 'sms' && recovery && (
              <Typography.Paragraph>
                Você receberá um código de verificação no seu nº celular
                cadastrado
                <Typography.Text style={{ color: theme.corPrimaria }}>
                  {' '}
                  {hideCelular(recovery.celular)}
                </Typography.Text>
              </Typography.Paragraph>
            )}
          </div>
          <span>Digite o código recebido</span>
          <MaskedInput
            mask="1111"
            name="code"
            maxLength={4}
            size="large"
            onChange={e => setCode(e.target.value)}
            style={{ textAlign: 'center', fontSize: 40 }}
          />
          <Button
            type="primary"
            size="large"
            style={{
              width: '100%',
              marginTop: 30,
            }}
            shape="round"
            onClick={handleSubmitCode}
            disabled={!(code && onlyNumbers(code).length === 4) || loading}
            loading={loading}
          >
            <b>Confirmar</b>
          </Button>
          <br />
          <br />
          <Button
            type="link"
            icon={<LeftOutlined />}
            onClick={() => setStep(prev => prev - 1)}
          >
            Requisitar novo código
          </Button>
        </div>

        {/* Criar Nova Senha */}
        <div hidden={step !== 3}>
          <div>
            <Typography.Title
              style={{
                textTransform: 'uppercase',
                fontSize: 20,
                marginBottom: 20,
              }}
            >
              Criar nova senha
            </Typography.Title>
          </div>

          <Form
            ref={formRef}
            name="passwords"
            onFinish={handleSubmitPassword}
            layout="vertical"
            validateMessages={validateMessages}
          >
            <Form.Item
              name="novaSenha"
              label="Digite uma Senha de 4 dígitos"
              rules={[
                {
                  pattern: new RegExp(/\d\d\d\d/g),
                  message: 'A senha deve conter 4 números!',
                },
              ]}
            >
              <Input.Password
                onChange={handleNewPassword}
                size="large"
                max={9999}
                maxLength={4}
              />
            </Form.Item>
            <Form.Item
              name="confirmacaoNovaSenha"
              label="Confirme sua Senha de 4 dígitos"
              rules={[
                {
                  required: true,
                  validator: checkPasswords,
                },
                {
                  pattern: new RegExp(/\d\d\d\d/g),
                  message: 'A senha deve conter 4 números!',
                },
              ]}
            >
              <Input.Password size="large" max={9999} maxLength={4} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{
                  width: '100%',
                  marginTop: 30,
                }}
                shape="round"
                disabled={loading || !newPassword}
                loading={loading}
              >
                <b>Confirmar nova senha</b>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
    </Container>
  );
};

export default ForgotPassword;
