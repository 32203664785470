import { useState } from 'react';

import api from 'services/api';
import { useAuth } from 'hooks/auth';

export default function useFriend() {
  const [loading, setLoading] = useState(false);
  const { authorization } = useAuth();

  async function saveFriend(value: any) {
    try {
      setLoading(true);

      // const newFriend = {
      //   cpf: value.cpf,
      //   dataNascimento: value.data,
      //   nomeCompleto: value.name,
      //   celular: value.phone,
      //   email: value.email,
      //   cep: value.cep,
      // };

      const { data } = (await api.post(`/amigo/cadastro`, value, {
        headers: { authorization },
      })) || {
        data: null,
      };

      return data && data.cpf;
    } finally {
      setLoading(false);
    }
  }

  return { saveFriend, loading };
}
