/* eslint-disable no-param-reassign */
import { Reducer } from 'react';

import produce from 'immer';
import { AnyAction } from 'redux';

const INITIAL_STATE = {
  isTabbarVisible: true,
  booting: true,
};

export interface LayoutStateType {
  isTabbarVisible: boolean;
  booting: boolean;
}

interface LayoutActionType extends AnyAction {
  payload: LayoutStateType;
}

const layout: Reducer<LayoutStateType, LayoutActionType> = (
  state = INITIAL_STATE,
  { type, payload },
) => {
  return produce(state, (draftState: LayoutStateType) => {
    switch (type) {
      case '@layout/TOGGLE_BOOTING': {
        draftState.booting = payload.booting;
        break;
      }
      case '@layout/TOGGLE_TABBAR_VIEW': {
        draftState.isTabbarVisible = payload.isTabbarVisible;
        break;
      }

      default:
    }
  });
};

export default layout;
