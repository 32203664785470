import { useAuth } from 'hooks/auth';
import { AmigoType } from 'model/Amigo';
import { ProdutoConfigType } from 'model/config/ProdutoConfig';
import React from 'react';
import formatCpf from '@brazilian-utils/format-cpf';

import { Container, Title, View, ViewItem } from './styles';
import { CardType } from 'model/card/Card';
import { toCurrency } from 'utils/StringHelper';
import { useTheme } from 'styled-components';

interface PaymentReviewProps {
  product: ProdutoConfigType;
  payment?: CardType;
  friend?: AmigoType;
  quantity: number;
  useSaldo: boolean;
}

const PaymentReview: React.FC<PaymentReviewProps> = ({
  product,
  payment,
  friend,
  quantity,
  useSaldo,
}) => {
  const { user } = useAuth();
  const theme = useTheme();

  return (
    <Container>
      <Title>CONFIRMAÇÃO DO PAGAMENTO</Title>
      <View>
        <ViewItem>Nome</ViewItem>
        <ViewItem>{user.Nome}</ViewItem>
      </View>
      <View>
        <ViewItem>CPF</ViewItem>
        <ViewItem>{formatCpf(user.cpf)}</ViewItem>
      </View>
      <View>
        <ViewItem>Produto</ViewItem>
        <ViewItem>{product.nome}</ViewItem>
      </View>
      {payment && (
        <View>
          <ViewItem>Forma de Pagamento</ViewItem>
          <ViewItem>
            {payment.numeroCartao}
            <span>
              | Cartão de {payment.tipoCartao === 1 ? ' Débito' : ' Crédito'}
            </span>
          </ViewItem>
        </View>
      )}

      {useSaldo && (
        <View>
          <ViewItem>Forma de Pagamento</ViewItem>
          <ViewItem>Utilizar do meu Saldo</ViewItem>
        </View>
      )}

      {friend && (
        <View>
          <ViewItem>Amigo</ViewItem>
          <ViewItem>
            {friend.nomeCompleto} | {formatCpf(friend.cpf)}
          </ViewItem>
        </View>
      )}
      <View>
        <ViewItem>Valor Total</ViewItem>
        {product.promocao && product.promocao.valorProduto && (
          <ViewItem style={{ color: theme.corPrimaria, fontWeight: 'bold' }}>
            {toCurrency(product.promocao.valorProduto * quantity)}
          </ViewItem>
        )}
      </View>
    </Container>
  );
};

export default PaymentReview;
