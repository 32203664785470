import React, { useCallback, useState } from 'react';

import ContornoBlue from 'assets/contorno-blue.png';
import ContornoRed from 'assets/contorno-red.png';

import { Dezena as LocalDezena, DezenaText } from './styles';

interface DezenasProps {
  dezena: string;
  width: number;
  dezenasSorteadas?: string[];
  realizado: boolean;
}

const Dezena: React.FC<DezenasProps> = ({
  dezena,
  width,
  dezenasSorteadas,
  realizado,
}) => {
  const isGoodDozen = useCallback(
    (dozen: string) => {
      if (!dezenasSorteadas) return null;
      const isGood = dezenasSorteadas.includes(dozen);
      if (isGood && dezenasSorteadas[dezenasSorteadas.length - 1] === dozen)
        return ContornoRed;
      return isGood ? ContornoBlue : null;
    },
    [dezenasSorteadas],
  );

  const [selected, setSelected] = useState(false);

  return (
    <LocalDezena
      isGoodDozen={isGoodDozen(dezena)}
      width={width}
      selected={selected}
      onClick={
        !dezenasSorteadas ? () => setSelected(prev => !prev) : () => null
      }
    >
      <DezenaText
        selected={selected}
        key={dezena}
        onClick={
          !dezenasSorteadas ? () => setSelected(prev => !prev) : () => null
        }
      >
        {dezena}
      </DezenaText>
    </LocalDezena>
  );
};

export default Dezena;
