import styled from 'styled-components';

export const Container = styled.div`
  .logo {
    margin: 8px;
    padding: 0;
    float: right;
    margin-right: 16px;
  }

  .header-background {
    background: #fff;
  }

  .trigger {
    margin: 16px;
    cursor: pointer;
    transition: color 0.3s;
  }
`;

export const Header = styled.header`
  padding: 12px 0;
  background: ${props => props.theme.corPrimaria};
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const HeaderContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  > img {
    height: 60px;
  }

  button {
    margin-left: auto;
    background: transparent;
    border: 0;

    svg {
      color: ${props => props.theme.corSecundaria};
      width: 24px;
      height: 24px;
    }
  }
`;

export const Main = styled.main`
  max-width: 1120px;
  margin: 32px auto;
  display: flex;
  flex-direction: column;
  /* margin: '24px 4px'; */
  min-height: 280px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  @media screen and (min-width: 600px) {
    padding: 20px;
  }
  padding: 4px;
`;

export const Title = styled.h1`
  color: ${props => props.theme.corTextoPrimaria};
  margin-left: 20px;
  font-size: 30px;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;
