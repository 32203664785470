import React, { useState, useEffect, useCallback } from 'react';
import { subDays, format } from 'date-fns';
import { PlusOutlined } from '@ant-design/icons';

import { useAuth } from 'hooks/auth';
import { useConfig } from 'hooks/config';
import useBalance from './useBalance';
import { ExtratoItemType } from 'model/ExtratoItem';
import { SaldoType } from 'model/Saldo';
import { toCurrency } from 'utils/StringHelper';
import formatCpf from '@brazilian-utils/format-cpf';

// import BalanceItem from './BalanceItem';
import {} from './styles';
import {
  Button,
  Card,
  Col,
  Empty,
  List,
  PageHeader,
  Radio,
  Row,
  Skeleton,
} from 'antd';
import { FaWallet } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Meta from 'antd/lib/card/Meta';
import Avatar from 'antd/lib/avatar/avatar';

import NoImage from 'assets/perfil-padrao-masculino.png';
import { Data } from './styles';

interface IntervalType {
  startDate: string;
  endDate: string;
}

function getInterval(days: number): IntervalType {
  const endDate = format(new Date(), 'dd/MM/yyyy');
  const startDate = format(subDays(new Date(), days), 'dd/MM/yyyy');
  return { startDate, endDate };
}

const filterList = [
  { value: 7, label: '7 dias' },
  { value: 30, label: '30 dias' },
  { value: 365, label: '365 dias' },
];

const Balance: React.FC = () => {
  const { authorization, user } = useAuth();
  const { config } = useConfig();
  const history = useHistory();
  const theme = useTheme();

  const { getSaldo, getExtrato } = useBalance(authorization as string);
  const [loadingSaldo, setLoadingSaldo] = useState(false);
  const [loadingExtrato, setLoadingExtrato] = useState(false);
  const [saldo, setSaldo] = useState<SaldoType>();
  const [extrato, setExtrato] = useState<ExtratoItemType[]>();
  const [filterSelected, setFilterSelected] = useState(0);

  const loadSaldo = useCallback(async (): Promise<void> => {
    setLoadingSaldo(true);
    if (config.produtos && config.produtos.length > 0) {
      const produto = config.produtos[0].codigo || 'apcapsp';
      setSaldo(await getSaldo(produto));
    }
    setLoadingSaldo(false);
  }, [config.produtos, getSaldo]);

  const loadExtrato = useCallback(async (): Promise<void> => {
    setLoadingExtrato(true);
    const { startDate, endDate } = getInterval(
      filterList[filterSelected].value,
    );
    setExtrato(await getExtrato(startDate, endDate));
    setLoadingExtrato(false);
  }, [getExtrato, filterSelected]);

  useEffect(() => {
    loadSaldo();
    setFilterSelected(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadExtrato();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelected]);

  return (
    <>
      <PageHeader
        backIcon={false}
        title={
          <>
            <h2 style={{ color: theme.corPrimaria }}>
              <FaWallet size={20} /> Extrato
            </h2>
          </>
        }
      />

      <Row gutter={[0, 16]}>
        <Col sm={{ order: 1, span: 14 }} xs={{ order: 2, span: 24 }}>
          <Radio.Group
            defaultValue={0}
            buttonStyle="solid"
            onChange={e => setFilterSelected(e.target.value)}
          >
            {filterList.map((fl, index) => (
              <Radio.Button key={fl.label} value={index}>
                {fl.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Col>
        <Col sm={{ order: 2, span: 10 }} xs={{ order: 1, span: 24 }}>
          <div style={{ textAlign: 'right' }}>
            <Card
              actions={[
                <Button
                  type="primary"
                  key="home"
                  onClick={() => {
                    history.push('/');
                  }}
                  style={{
                    textTransform: 'uppercase',
                    width: '100%',
                  }}
                  icon={<PlusOutlined />}
                  size="large"
                >
                  {config.appConfig?.glossario?.adquira ||
                    'Realizar uma Compra'}
                </Button>,
              ]}
            >
              <Skeleton loading={loadingSaldo} avatar active>
                <Meta
                  avatar={
                    user.urlImageProfile ? (
                      <Avatar size="large" src={user.urlImageProfile} />
                    ) : (
                      <Avatar size="large" src={NoImage} />
                    )
                  }
                  title={user && user.Nome && user.Nome?.split(' ')[0]}
                  description={
                    <>
                      <div style={{ fontSize: 20 }}>Seu saldo atual é de:</div>
                      {saldo && (
                        <div style={{ fontSize: 24, fontWeight: 'bold' }}>
                          {toCurrency(saldo?.carteira?.saldo) || 0}
                        </div>
                      )}
                    </>
                  }
                />
              </Skeleton>
            </Card>
          </div>
        </Col>
      </Row>

      <List
        // loading={loadingExtrato}
        itemLayout="horizontal"
        dataSource={extrato}
        locale={{
          emptyText: (
            <Empty
              description={`Nenhum registro encontrado nos últimos ${filterList[filterSelected].label}`}
            />
          ),
        }}
        renderItem={item => (
          <List.Item
            style={{
              backgroundColor: 'white',
              padding: 10,
            }}
          >
            <Skeleton avatar title={false} loading={loadingExtrato} active>
              <List.Item.Meta
                avatar={
                  <Data style={{ opacity: item.pessoaPresenteada ? 0.6 : 1 }}>
                    <div
                      style={{
                        color: 'white',
                        fontSize: 18,
                        fontWeight: 'bold',
                      }}
                    >
                      {item.data.substr(0, 5)}
                    </div>
                    <div style={{ color: 'white', fontSize: 14 }}>
                      {item.data.substr(-5)}
                    </div>
                  </Data>
                }
                title={
                  <h3 style={{ color: theme.corPrimaria }}>{item.produto}</h3>
                }
                description={
                  <>
                    <div>
                      {item.formaPagamento} {item.numeroCartao && ' | '}
                      {item.numeroCartao}
                    </div>
                    {item.pessoaPresenteada?.cpf && (
                      <>
                        <div style={{ fontWeight: 'bold' }}>
                          Amigo presenteado
                        </div>
                        <div style={{ fontStyle: 'italic' }}>
                          {item.pessoaPresenteada.nome}
                        </div>
                        <div style={{ fontStyle: 'italic' }}>
                          {formatCpf(item.pessoaPresenteada.cpf)}
                        </div>
                      </>
                    )}
                  </>
                }
              />
              <div style={{ textAlign: 'right' }}>
                <h3>{toCurrency(item.valor)}</h3>
                <div className="ant-list-item-meta-title">
                  Autenticação {item.autenticacao}
                </div>
              </div>
            </Skeleton>
          </List.Item>
        )}
      />
    </>
  );
};

export default Balance;
