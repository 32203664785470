import { useConfig } from 'hooks/config';
import { ProdutoConfigType } from 'model/config/ProdutoConfig';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Row, Col, Button, Spin, PageHeader, Empty } from 'antd';

import { Container, Title } from './styles';
import { useHistory } from 'react-router-dom';
import api from 'services/api';
import { CuponsTotalType } from 'model/CuponsTotal';
import { useAuth } from 'hooks/auth';
import { FaTicketAlt } from 'react-icons/fa';
import { useTheme } from 'styled-components';

const { Meta } = Card;

const TicketsProductList: React.FC = () => {
  const { products } = useConfig();
  const { authorization } = useAuth();
  const theme = useTheme();
  const { product } = useConfig();

  const [produtos, setProdutos] = useState<ProdutoConfigType[]>([]);
  const [loading, setLoading] = useState(false);

  const getCuponsTotal = useCallback(async (): Promise<CuponsTotalType> => {
    const response = await api.get('/cupom/total', {
      headers: { authorization },
    });
    return response && response.data;
  }, [authorization]);

  const getProdutos = useCallback(async () => {
    setLoading(true);

    if (products) {
      const cuponsTotal: CuponsTotalType = await getCuponsTotal();
      const userProds = cuponsTotal?.totais.map(t => t.produto);
      if (userProds) {
        setProdutos(
          products
            .filter(p => p.usadoComoBrinde)
            .filter(p => userProds.includes(p.codigo)),
        );
      }
    }
    setLoading(false);
  }, [products, getCuponsTotal]);

  const history = useHistory();

  useEffect(() => {
    getProdutos();
  }, [getProdutos]);

  interface ItemProps {
    product: ProdutoConfigType;
  }

  const Item: React.FC<ItemProps> = ({
    product,
  }: {
    product: ProdutoConfigType;
  }) => {
    return (
      <Col style={{ marginBottom: 40 }}>
        <Card
          hoverable
          onClick={() => history.push('/tickets', { product: product })}
          style={{ width: 300 }}
          cover={
            <img
              alt={product.titulo}
              src={product.icone}
              height={300}
              style={{ padding: 10 }}
            />
          }
          actions={[
            <div style={{ paddingRight: 20, paddingLeft: 20 }}>
              <Button
                type="primary"
                block
                shape="round"
                size="large"
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
                onClick={() => history.push('/tickets', { product: product })}
              >
                Ver meus {product.config?.glossario?.certificados}
              </Button>
            </div>,
          ]}
        >
          <Meta
            title={
              <Title style={{ color: product.config?.web?.corPrimaria }}>
                {product.titulo}
              </Title>
            }
          />
        </Card>
      </Col>
    );
  };

  const renderMain = () => {
    if (!loading && produtos && produtos.length) {
      return produtos.map(product => (
        <Item key={product.codigo} product={product} />
      ));
    }

    if (!loading && produtos.length === 0) {
      return (
        <Empty
          description={`Você não tem nenhum ${product?.config?.glossario?.certificado} ainda.`}
        >
          <Button type="primary" onClick={() => history.push('/')}>
            {product?.config?.glossario?.adquira} agora mesmo
          </Button>
        </Empty>
      );
    }
  };

  return (
    <>
      <PageHeader
        backIcon={false}
        title={
          <>
            <h2
              style={{
                color: theme.corPrimaria,
                whiteSpace: 'pre-wrap',
                textAlign: 'center',
              }}
            >
              <FaTicketAlt size={20} /> Selecione o produto para consultar
            </h2>
          </>
        }
      />
      <Container>
        <Card>
          <Row style={{ justifyContent: 'center' }} gutter={16}>
            {renderMain()}
            {loading && <Spin size="large" />}
          </Row>
        </Card>
      </Container>
    </>
  );
};

export default TicketsProductList;
