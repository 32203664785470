import React, { useCallback, useEffect, useState } from 'react';
import { Form, Select, Modal, Row, Col, message, Button } from 'antd';
import { ImTicket } from 'react-icons/im';
import { useAuth } from 'hooks/auth';
import api from 'services/api';
import { Container, Dezena, ItemOption, InputDezena, COL } from './styles';
import { ProdutoConfigType } from 'model/config/ProdutoConfig';
import { useTheme } from 'styled-components';
import { onlyNumbers } from 'utils/StringHelper';
import { MaskedInput } from 'antd-mask-input';
import TicketOption from './components/TicketOption';
import { ReservaType } from 'model/Reserva';
import { formatNumberTicket } from 'utils/StringHelper';
import TicketReview from './components/TicketReview';
const { Option } = Select;

const Label = ({
  icon,
  text,
  color,
}: {
  icon: JSX.Element;
  text: string;
  color: string;
}) => {
  return (
    <span style={{ color }}>
      {icon} <span>&nbsp;{text}</span>
    </span>
  );
};

function compare(a: any, b: any) {
  if (a.id > b.id) {
    return 1;
  }
  if (a.id < b.id) {
    return -1;
  }
  // a must be equal to b
  return 0;
}

interface TicketSelectProps {
  product: ProdutoConfigType;
  quantity: number;
  withReserva: (v: boolean) => void;
  setReservas: (r: ReservaType[]) => void;
  reservas: ReservaType[];
}

const DEFAULT_DOZENS = [
  { id: 'd1', value: '' },
  { id: 'd2', value: '' },
  { id: 'd3', value: '' },
  { id: 'd4', value: '' },
];

const TicketSelect: React.FC<TicketSelectProps> = ({
  product,
  quantity,
  withReserva,
  setReservas,
  reservas,
}) => {
  const { authorization } = useAuth();
  const theme = useTheme();

  const [reserva, setReserva] = useState<ReservaType>();

  const [modalVisible, setModalVisible] = useState(false);
  const [modalReviewVisible, setModalReviewVisible] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [digitos, setDigitos] = useState('');
  const [loading, setLoading] = useState(false);
  const [dezenas, setDezenas] = useState(DEFAULT_DOZENS);
  const [ticket, setTicket] = useState<string>();
  const [option, setOption] = useState<ReservaType>();

  const handleModalCancel = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleModalReviewCancel = useCallback(() => {
    setModalReviewVisible(false);
  }, []);

  const handleChange = useCallback(
    value => {
      if (value.includes('random')) {
        setModalVisible(true);
        setTicket(value);
      } else {
        const id = parseInt(value.replace('choose', ''));
        setOption(reservas.find(r => r.idTituloReserva === id));
        setModalReviewVisible(true);
      }
    },
    [reservas],
  );

  useEffect(() => {
    withReserva(reservas.length > 0);
  }, [reservas, withReserva]);

  const handleDigitosChange = useCallback(e => {
    setDezenas(DEFAULT_DOZENS);
    let { value } = e.target;
    setDigitos(value);
  }, []);

  const handleInputChange = (e: any, id: string) => {
    let { value } = e.target;
    value = onlyNumbers(value);
    const reg = /6[0-0]|5[0-9]|4[0-9]|3[0-9]|2[0-9]|1[0-9]|0[1-9]/;
    if (!reg.test(value) && value.length === 2) {
      setDezenas(
        [...dezenas.filter(d => d.id !== id), { id, value: '' }].sort(compare),
      );
      message.error('A dezena deve estar entre 01 e 60.');
    } else if (dezenas.filter(d => d.id !== id).find(d => d.value === value)) {
      setDezenas(
        [...dezenas.filter(d => d.id !== id), { id, value: '' }].sort(compare),
      );
      message.error('Esta dezena já está selecionada.');
    } else {
      setDezenas(
        [...dezenas.filter(d => d.id !== id), { id, value }].sort(compare),
      );
      setDigitos('');
    }
  };

  const loadTickets = useCallback(async (): Promise<void> => {
    setLoading(true);
    let data = {};
    if (digitos && digitos !== '') {
      data = { codProduto: product.codigo, digitos: onlyNumbers(digitos) };
    } else {
      data = { codProduto: product.codigo, dezenas: dezenas.map(d => d.value) };
    }
    const response = await api.post(
      '/certificadoDeContribuicao/reserva',
      data,
      {
        headers: { authorization },
      },
    );

    if (response && response.data) {
      setReserva(response.data);
      setShowOptions(true);
    }
    setLoading(false);
  }, [authorization, dezenas, digitos, product.codigo]);

  const handleBuscar = () => {
    loadTickets();
  };

  const cancelReserva = useCallback(async (): Promise<void> => {
    setLoading(true);
    await api.post(
      '/certificadoDeContribuicao/reserva/cancelar',
      { codProduto: product.codigo },
      {
        headers: { authorization },
      },
    );

    setReservas([]);
    setReserva(undefined);
    setOption(undefined);
    setTicket(undefined);

    setLoading(false);
  }, [authorization, product.codigo, setReservas]);

  const handleTrocarReserva = useCallback(() => {
    setModalReviewVisible(false);
    cancelReserva();
  }, [cancelReserva]);

  const confirmReserva = useCallback(
    async (idTituloReserva: number): Promise<void> => {
      setLoading(true);
      await api.post(
        '/certificadoDeContribuicao/reserva/confirma',
        { codProduto: product.codigo, idTituloReserva },
        {
          headers: { authorization },
        },
      );

      setLoading(false);
      setTicket(undefined);
    },
    [authorization, product.codigo],
  );

  const handleBack = () => {
    cancelReserva();
    setShowOptions(false);
    setReserva(undefined);
  };

  const handleSelect = (option: ReservaType) => {
    confirmReserva(option.idTituloReserva);
    setReservas([...reservas, option]);
    setShowOptions(false);
    setReserva(undefined);
    setModalVisible(false);
  };

  const renderOptions = useCallback(() => {
    const opts = [];
    for (let index = 1; index <= quantity; index++) {
      opts.push(index);
    }
    return opts.map(index => {
      return reservas && reservas[index - 1] ? (
        <Option
          key={index}
          value={'choose' + reservas[index - 1].idTituloReserva}
          label={
            product.config?.glossario?.certificado +
            ' Selecionado: ' +
            formatNumberTicket(reservas[index - 1].certificados[0].numero)
          }
        >
          <ItemOption>
            {product.config?.glossario?.certificado +
              ' Selecionado: ' +
              formatNumberTicket(reservas[index - 1].certificados[0].numero)}
          </ItemOption>
        </Option>
      ) : (
        <Option
          key={index}
          value={'random' + index}
          label={
            product.config?.glossario?.certificado +
            ' ' +
            index +
            ' Aleatório | Selecione para escolher o ' +
            product.config?.glossario?.certificado
          }
          disabled={index - reservas.length >= 2}
        >
          <ItemOption>
            {product.config?.glossario?.certificado +
              ' ' +
              index +
              ' Aleatório | Selecione para escolher o ' +
              product.config?.glossario?.certificado}
          </ItemOption>
        </Option>
      );
    });
  }, [quantity, reservas, product.config]);

  return (
    <>
      <Container>
        <Form layout="vertical" style={{ width: '90%' }}>
          <Form.Item
            label={
              <Label
                icon={<ImTicket />}
                text={
                  'Escolha do ' +
                  product.config?.glossario?.numeroDo +
                  ' (opcional)'
                }
                color={product.config?.web?.corPrimaria || theme.corPrimaria}
              />
            }
            name="layout"
          >
            <Select
              style={{ width: '100%' }}
              placeholder="Selecione a forma de atribuição"
              onChange={handleChange}
              onSelect={handleChange}
              optionLabelProp="label"
              value={ticket}
            >
              {renderOptions()}
            </Select>
          </Form.Item>
        </Form>
      </Container>

      <Modal
        title={<h3>{'Escolher ' + product.config?.glossario?.certificado}</h3>}
        visible={modalVisible}
        onCancel={handleModalCancel}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <div hidden={showOptions}>
          <Row
            align="middle"
            style={{ backgroundColor: theme.corPrimaria, borderRadius: 10 }}
          >
            <Col
              className="md-font"
              style={{ textAlign: 'center', color: 'white' }}
              span={24}
            >
              DIGITE ATÉ 4 DEZENAS ENTRE 01 E 60
            </Col>
            <COL span={6}>
              <Dezena>
                <InputDezena
                  bordered={false}
                  mask="11"
                  placeholder="00"
                  min={1}
                  max={60}
                  maxLength={2}
                  autoFocus
                  onChange={event => handleInputChange(event, 'd1')}
                  value={dezenas[0].value}
                />
              </Dezena>
            </COL>
            <COL span={6}>
              <Dezena>
                <InputDezena
                  bordered={false}
                  mask="11"
                  placeholder="00"
                  min={1}
                  max={60}
                  maxLength={2}
                  onChange={event => handleInputChange(event, 'd2')}
                  value={dezenas[1].value}
                />
              </Dezena>
            </COL>
            <COL span={6}>
              <Dezena>
                <InputDezena
                  bordered={false}
                  mask="11"
                  placeholder="00"
                  min={1}
                  max={60}
                  maxLength={2}
                  onChange={event => handleInputChange(event, 'd3')}
                  value={dezenas[2].value}
                />
              </Dezena>
            </COL>
            <COL span={6}>
              <Dezena>
                <InputDezena
                  bordered={false}
                  mask="11"
                  placeholder="00"
                  min={1}
                  max={60}
                  maxLength={2}
                  onChange={event => handleInputChange(event, 'd4')}
                  value={dezenas[3].value}
                />
              </Dezena>
            </COL>
          </Row>
          <Row>
            <Col style={{ textAlign: 'center', fontSize: 20 }} span={24}>
              ou
            </Col>
          </Row>
          <Row
            align="middle"
            style={{
              borderColor: theme.corPrimaria,
              borderRadius: 10,
              borderWidth: 1,
            }}
          >
            <Col
              style={{
                textAlign: 'center',
                fontSize: 18,
                textTransform: 'uppercase',
              }}
              span={24}
            >
              DIGITE OS 2 ÚLTIMOS NÚMEROS DO{' '}
              {product.config?.glossario?.certificado}
            </Col>
            <Col span={24}>
              <MaskedInput
                mask="XXX.X11"
                onChange={handleDigitosChange}
                style={{ borderRadius: 10, fontSize: 48, textAlign: 'center' }}
                value={digitos}
              />
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'center', marginTop: 20 }} span={24}>
              <Button
                type="primary"
                size="large"
                loading={loading}
                shape="round"
                onClick={handleBuscar}
              >
                BUSCAR
              </Button>
            </Col>
          </Row>
        </div>

        <div hidden={!showOptions}>
          {reserva && product && (
            <TicketOption
              product={product}
              option={reserva}
              onBack={handleBack}
              onSelect={handleSelect}
              digitos={digitos}
              dezenas={dezenas}
            />
          )}
        </div>
      </Modal>

      <Modal
        title={
          <h3>{product.config?.glossario?.certificado + ' escolhido'} </h3>
        }
        visible={modalReviewVisible}
        onCancel={handleModalReviewCancel}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        {option && product && (
          <TicketReview
            product={product}
            option={option}
            onCancel={handleTrocarReserva}
          />
        )}
      </Modal>
    </>
  );
};

export default TicketSelect;
