/* eslint-disable no-restricted-globals */
interface popupcenterProps {
  url: string;
  title?: string;
  w: number;
  h: number;
}
const popupCenter = ({ url, title, w, h }: popupcenterProps) => {
  // Fixes dual-screen position Most browsers Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  let newWindow;
  if (title) {
    newWindow = window.open(
      url,
      title,
      `scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `,
    );
  } else {
    newWindow = window.open(
      url,
      `scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `,
    );
  }

  if (window.focus && newWindow) {
    newWindow.focus();
  }
};

export default popupCenter;
