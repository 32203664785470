import React, { useState, useCallback } from 'react';
import { Modal } from 'antd';

import { ProdutoConfigType } from 'model/config/ProdutoConfig';
import { GanhadorType } from 'model/Ganhador';

import ContornoRed from 'assets/contorno-red.png';
import NoImage from 'assets/perfil-padrao-masculino.png';
import Selo from 'assets/selo.png';
import VerDezenas from 'assets/ver-dezenas.png';

import {
  Container,
  Title,
  Image,
  ContainerTexts,
  Description,
  DescriptionPDV,
  ImageIconContainter,
  ImageIcon,
  DezenaContainer,
  Dezena,
  DezenaText,
  Feedback,
  FeefbackContainer,
} from './styles';

interface WinnerProps {
  ganhador: GanhadorType;
  lastDezena: string | null;
  extra?: boolean;
  produtoConfig?: ProdutoConfigType;
  width: number;
}

const Winner: React.FC<WinnerProps> = ({
  ganhador,
  lastDezena,
  extra,
  produtoConfig,
  width,
}) => {
  const [modalOpened, setModalOpened] = useState(false);

  const openModal = (): void => {
    setModalOpened(true);
  };

  const renderWinner = useCallback(
    (isModal: boolean) => (
      <Container width={width}>
        {ganhador.urlImagem ? (
          <Image src={ganhador.urlImagem} />
        ) : (
          <Image style={{ opacity: 0.3 }} src={NoImage} />
        )}
        <ContainerTexts>
          <Description>
            {produtoConfig?.config?.glossario?.numeroDo || 'Nº '}
            &nbsp;
            <Title>{!extra ? ganhador.titulo : ganhador.numeroExtra}</Title>
          </Description>
          <Description>{ganhador.nome}</Description>
          <Description>
            {ganhador.bairro} - {ganhador.cidade}/{ganhador.estado}
          </Description>
          {!!(ganhador && ganhador.pdv) && (
            <Description>
              PDV:&nbsp;
              <DescriptionPDV>{ganhador.pdv}</DescriptionPDV>
            </Description>
          )}
        </ContainerTexts>
        {!isModal && (
          <ImageIconContainter>
            <ImageIcon src={VerDezenas} />
          </ImageIconContainter>
        )}
        {isModal && (
          <ImageIconContainter>
            <ImageIcon src={Selo} />
          </ImageIconContainter>
        )}
      </Container>
    ),
    [ganhador, extra, produtoConfig, width],
  );

  return (
    <>
      <div onClick={() => openModal()}>{renderWinner(false)}</div>

      <Modal
        visible={modalOpened}
        onCancel={() => setModalOpened(false)}
        okButtonProps={{ hidden: true }}
        cancelText="Fechar"
      >
        <div
        // callback={() => setModalOpened(false)}
        >
          {renderWinner(true)}

          {!!ganhador.feedBack && ganhador.feedBack.descricao && (
            <FeefbackContainer>
              <Feedback>{ganhador.feedBack.descricao}</Feedback>
            </FeefbackContainer>
          )}
          {ganhador.dezenas && !!ganhador.dezenas.length && (
            <DezenaContainer
              vertical={produtoConfig?.config?.orientacaoDezenas === 'vertical'}
              width={width}
            >
              {ganhador.dezenas &&
                ganhador.dezenas.map(dezena => (
                  <Dezena
                    key={dezena}
                    isGoodDozen={
                      dezena === lastDezena ? ContornoRed : undefined
                    }
                    width={width}
                  >
                    <DezenaText key={dezena}>{dezena}</DezenaText>
                  </Dezena>
                ))}
            </DezenaContainer>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Winner;
