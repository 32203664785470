import React, { useCallback, useEffect, useState } from 'react';
import { Form, Modal, Select } from 'antd';
import { useAuth } from 'hooks/auth';
import api from 'services/api';
import { Container, ItemOption, NewFriend } from './styles';
import { AmigoType } from 'model/Amigo';
import formatCpf from '@brazilian-utils/format-cpf';
import { ProdutoConfigType } from 'model/config/ProdutoConfig';
import { useTheme } from 'styled-components';
import { FaUserFriends } from 'react-icons/fa';
import Friend from 'pages/Friend';

const { Option } = Select;
const Label = ({
  icon,
  text,
  color,
}: {
  icon: JSX.Element;
  text: string;
  color: string;
}) => {
  return (
    <span style={{ color }}>
      {icon} <span>&nbsp;{text}</span>
    </span>
  );
};

interface PurchaseFriendProps {
  product: ProdutoConfigType;
  setFriend: (friend: AmigoType | undefined) => void;
}

const PurchaseFriend: React.FC<PurchaseFriendProps> = ({
  product,
  setFriend,
}) => {
  const { authorization } = useAuth();
  const [loading, setLoading] = useState(false);
  const [friends, setFriends] = useState<AmigoType[]>([]);
  const theme = useTheme();

  const [modalNewFriendVisible, setModalNewFriendVisible] = useState(false);
  const [selected, setSelected] = useState<number>();

  const loadAmigos = useCallback(
    async (cpf?: string): Promise<void> => {
      setLoading(true);
      const response = await api.get('/amigo/lista', {
        headers: { authorization },
      });

      if (response && response.data) {
        setFriends(response.data);
        if (cpf) {
          const f = response.data.find((a: any) => a.cpf === cpf);
          setFriend(f);
          setSelected(f.id);
        }
      }
      setLoading(false);
    },
    [authorization, setFriend],
  );

  useEffect(() => {
    loadAmigos();
  }, [loadAmigos]);

  const handleChange = useCallback(
    (value: number) => {
      if (value === 0) {
        setModalNewFriendVisible(true);
      } else {
        setFriend(friends.find(f => f.id === value));
      }
      setSelected(value);
    },
    [friends, setFriend],
  );

  const handleSaveNewFriend = useCallback(
    async (cpf: string) => {
      setModalNewFriendVisible(false);
      await loadAmigos(cpf);
    },
    [loadAmigos],
  );

  const handleCancelNewFriend = useCallback(() => {
    setModalNewFriendVisible(false);
    setFriend(undefined);
    setSelected(undefined);
  }, [setFriend]);

  return (
    <>
      <Container>
        <Form layout="vertical" style={{ width: '90%' }}>
          <Form.Item
            label={
              <Label
                icon={<FaUserFriends />}
                text="Amigo"
                color={product.config?.web?.corPrimaria || theme.corPrimaria}
              />
            }
          >
            <Select
              style={{ width: '100%' }}
              placeholder="Selecione um Amigo"
              onChange={handleChange}
              optionLabelProp="label"
              value={selected}
              loading={loading}
            >
              {friends &&
                friends.map(friend => (
                  <Option
                    value={friend.id}
                    key={friend.id}
                    label={
                      <>
                        {friend.nomeCompleto}&nbsp;
                        <span>| CPF {formatCpf(friend.cpf)}</span>
                      </>
                    }
                  >
                    <ItemOption>
                      <>
                        {friend.nomeCompleto}&nbsp;
                        <span>| CPF {formatCpf(friend.cpf)}</span>
                      </>
                    </ItemOption>
                  </Option>
                ))}
              <Option
                value={0}
                key={0}
                label={
                  <NewFriend style={{ marginTop: 34 }}>
                    <FaUserFriends size={46} color="green" />
                    <div>Adicionar Novo Amigo</div>
                  </NewFriend>
                }
              >
                <NewFriend>
                  <FaUserFriends size={46} color="green" />
                  <div>Adicionar Novo Amigo</div>
                </NewFriend>
              </Option>
            </Select>
          </Form.Item>
        </Form>
      </Container>
      <Modal
        title={
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <FaUserFriends color={theme.corPrimaria} size={24} />
            &nbsp;Adicionar Amigo
          </p>
        }
        visible={modalNewFriendVisible}
        okButtonProps={{ hidden: true }}
        onCancel={handleCancelNewFriend}
      >
        <Friend isModal={true} onSave={handleSaveNewFriend} />
      </Modal>
    </>
  );
};

export default PurchaseFriend;
