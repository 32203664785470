import React, { useState } from 'react';
import { Col, Modal, Row } from 'antd';
import { FaCalendar, FaCircle, FaClock } from 'react-icons/fa';

import { promotionColors } from 'utils';
import { ProdutoConfigType } from 'model/config/ProdutoConfig';
import { CupomType } from 'model/Cupom';
import { ProdutoType } from 'model/Produto';

import TicketDetail from '../../TicketDetail';
import {
  ContainerDateTime,
  ContainerDateTimeRow,
  LabelDateTime,
  ValueDateTime,
  ContainerTitle,
  Title,
  ContainerIcon,
  ContainerDateTimeText,
  NumberTicket,
  ContainerRow,
  LabelNumberTicket,
  StatusContainer,
  StatusText,
  Wrapper,
  ActionContainer,
  ActionText,
} from './styles';

interface TicketItemProps {
  ticket: CupomType;
  index: number;
  product: ProdutoType;
  produtoConfig: ProdutoConfigType;
}

const getPromotionColor = (colorIndex: number): string => {
  return promotionColors[colorIndex];
};

const getDate = (date: string): string => {
  if (!date) return '';
  const dateTime = date.split(' ');
  const aDate = dateTime[0].split('/');
  return `${aDate[0]}/${aDate[1]}`;
};
const getTime = (date: string): string => {
  if (!date) return '';
  const dateTime = date.split(' ');
  return dateTime[1];
};

const TicketItem: React.FC<TicketItemProps> = ({
  ticket,
  index,
  product,
  produtoConfig,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleViewResultsPress = (): void => {
    setModalVisible(true);
  };

  return (
    <>
      <Wrapper
        color={getPromotionColor(ticket.promocao.cor)}
        onClick={handleViewResultsPress}
        hoverable
      >
        <Row>
          <Col md={6} xs={24}>
            <ContainerDateTime>
              <ContainerDateTimeRow
                color={getPromotionColor(ticket.promocao.cor)}
              >
                <ContainerIcon color={getPromotionColor(ticket.promocao.cor)}>
                  <FaCalendar size={16} color="white" />
                </ContainerIcon>
                <ContainerDateTimeText>
                  <LabelDateTime>Data</LabelDateTime>
                  <ValueDateTime>
                    {getDate(ticket.promocao.dataSorteioPrincipal)}
                  </ValueDateTime>
                </ContainerDateTimeText>
              </ContainerDateTimeRow>

              <ContainerDateTimeRow
                color={getPromotionColor(ticket.promocao.cor)}
              >
                <ContainerIcon color={getPromotionColor(ticket.promocao.cor)}>
                  <FaClock size={18} color="white" />
                </ContainerIcon>
                <ContainerDateTimeText>
                  <LabelDateTime>Horário</LabelDateTime>
                  <ValueDateTime>
                    {getTime(ticket.promocao.dataSorteioPrincipal)}
                  </ValueDateTime>
                </ContainerDateTimeText>
              </ContainerDateTimeRow>
            </ContainerDateTime>
          </Col>
          <Col md={12} xs={24}>
            <ContainerTitle>
              <LabelNumberTicket>
                {produtoConfig?.config?.glossario?.numeroDo}
              </LabelNumberTicket>
              <ContainerRow>
                <NumberTicket color={getPromotionColor(ticket.promocao.cor)}>
                  {ticket.numeroSorte[0]}
                </NumberTicket>
              </ContainerRow>
              <Title color={getPromotionColor(ticket.promocao.cor)}>
                {ticket.promocao.titulo}
              </Title>
            </ContainerTitle>
          </Col>
          <Col md={6} xs={24}>
            <ContainerTitle>
              <ActionContainer color={getPromotionColor(ticket.promocao.cor)}>
                <ActionText>{ticket.acaoSigla}</ActionText>
              </ActionContainer>
              <StatusContainer>
                <div>
                  <FaCircle
                    style={{ marginLeft: 4 }}
                    color={ticket.promocao.finalizada ? '#B51C24' : '#72F54A'}
                  />
                </div>
                <StatusText>
                  {ticket.promocao.finalizada
                    ? 'Encerrado'
                    : 'Você está concorrendo'}
                </StatusText>
              </StatusContainer>
            </ContainerTitle>
          </Col>
        </Row>
      </Wrapper>

      <Modal
        okButtonProps={{ hidden: true }}
        cancelText="Fechar"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
      >
        <TicketDetail
          ticket={ticket}
          product={product}
          produtoConfig={produtoConfig}
          toggleModal={setModalVisible}
        />
      </Modal>
    </>
  );
};

export default TicketItem;
