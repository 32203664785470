import { Space } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const Title = styled.h3``;

export const View = styled(Space)`
  /* display: flex; */
  /* flex-direction: row; */
  justify-content: space-between;
  /* align-items: center; */
  padding: 0 20px;
  width: 100%;
`;
export const ViewItem = styled.div`
  /* border-bottom: dotted 1px #00000020; */
  flex: 0.5;
`;
