import { WebConfigType } from 'model/config/ConfigDoProduto';
import styled from 'styled-components';

interface ColorsProps {
  colors: WebConfigType | undefined;
}

export const ContainerHeader = styled.div`
  width: 100%;
`;

export const ContainerTotal = styled.div<ColorsProps>`
  border-radius: 10px;
  margin: 20px;
  background-color: ${props =>
    (props.colors && props.colors.corPrimaria) || props.theme.corPrimaria};
  min-height: 55px;
  flex-direction: row;
  padding: 4px 10px;
  justify-content: space-between;
  display: flex;
`;

export const BoxTotal = styled.div`
  flex-direction: column;
  display: flex;
`;

export const LabelTotal = styled.span`
  color: white;
  font-size: 14px;
`;
export const Total = styled.span`
  color: white;
  font-size: 28px;
  font-weight: bold;
`;

export const BoxQuantity = styled.div`
  flex-direction: row;
  justify-content: space-evenly;
  flex: 0.5;
  align-items: center;
  display: flex;
`;

export const QuantityTotal = styled.span`
  color: white;
  font-size: 40px;
  font-weight: bold;
`;
