import { Tooltip } from 'antd';
import React, { useRef, useState } from 'react';
import { FaCopy } from 'react-icons/fa';

interface LinhaDigitavelProps {
  linhaDigitavel: string;
}
let timeCopy: number;

const LinhaDigitavel: React.FC<LinhaDigitavelProps> = ({ linhaDigitavel }) => {
  const [copied, setCopied] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleCopy = () => {
    if (timeCopy) clearTimeout(timeCopy);
    if (textAreaRef.current) {
      textAreaRef.current.select();
      document.execCommand('copy');
      // This is just personal preference.
      // I prefer to not show the whole text area selected.
      setCopied(true);
      timeCopy = setTimeout(() => {
        setCopied(false);
        clearTimeout(timeCopy);
      }, 2000);
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Tooltip
        title={copied ? 'Copiado' : 'Clique para copiar'}
        color={copied ? 'green' : 'blue'}
      >
        <b onClick={handleCopy}>
          {linhaDigitavel}&nbsp;
          <FaCopy />
        </b>
      </Tooltip>
      <textarea
        ref={textAreaRef}
        value={String(linhaDigitavel).replace(/[^\d]/g, '')}
        style={{ display: 'block', opacity: 0, width: 0, height: 0 }}
      />
    </div>
  );
};

export default LinhaDigitavel;
