import React from 'react';
import { Button } from 'antd';
import { Container } from './styles';
import { ProdutoConfigType } from 'model/config/ProdutoConfig';
import { ReservaType } from 'model/Reserva';
import TicketChance from '../TicketChance';
import { useTheme } from 'styled-components';

interface TicketReviewProps {
  product: ProdutoConfigType;
  option: ReservaType;
  onCancel: () => void;
}

const TicketReview: React.FC<TicketReviewProps> = ({
  product,
  option,
  onCancel,
}) => {
  const theme = useTheme();

  return (
    <>
      {option && (
        <>
          <TicketChance option={option} product={product} />
          <Container>
            <Button
              onClick={() => onCancel()}
              type="primary"
              style={{
                borderRadius: 10,
                borderColor: 'transparent',
                backgroundColor: theme.corPrimaria,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: 20,
                padding: '24px 40px',
              }}
            >
              QUERO TROCAR
            </Button>
          </Container>
        </>
      )}
    </>
  );
};

export default TicketReview;
