import React from 'react';

import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';

const Page404: React.FC = () => {
  const history = useHistory();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sinto muito, a página que você quer visitar não existe."
      extra={
        <Button type="primary" onClick={() => history.push('/')}>
          Voltar para Home
        </Button>
      }
    />
  );
};

export default Page404;
