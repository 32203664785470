import React from 'react';
import { Carousel } from 'antd';
import { ProdutoConfigType } from 'model/config/ProdutoConfig';

interface BannerProps {
  product: ProdutoConfigType;
}

const Checkout: React.FC<BannerProps> = ({ product }) => {
  return (
    <>
      {product && (
        <Carousel autoplay autoplaySpeed={2000} easing="ease-in">
          {product.banners?.map(banner => (
            <img key={banner.url} alt={banner.url} src={banner.url}></img>
          ))}
        </Carousel>
      )}
    </>
  );
};

export default Checkout;
