import React, { useState, useCallback, useEffect, useRef } from 'react';

import {
  FaCalendar,
  FaClock,
  FaExternalLinkAlt,
  FaTicketAlt,
} from 'react-icons/fa';

import { useAuth } from 'hooks/auth';
import { useConfig } from 'hooks/config';
import { ProdutoConfigType } from 'model/config/ProdutoConfig';
import { CupomType } from 'model/Cupom';
import { CupomDetail } from 'model/detail/Cupom';
import { SorteioType } from 'model/detail/Sorteio';
import { ProdutoType } from 'model/Produto';
import Winner from '../Winner';
import { Subtitle } from '../Winner/styles';
import api from 'services/api';
import { formatNumberTicket } from 'utils/StringHelper';
import { promotionColors } from 'utils';

import Dashboard from './Dashboard';
import Dezenas from './Dezenas';
import NumeroSorte from './NumeroSorte';
import {
  Wrapper,
  Container,
  DezenaContainer,
  Dezena,
  Image,
  Footer,
  ProductImage,
  FooterContent,
  SecureCode,
  FooterInfos,
  ContainerDateTimeRow,
  ContainerIcon,
  ContainerDateTimeText,
  LabelDateTime,
  ValueDateTime,
  ContainerText,
  ButtonText,
  ContainerRegulation,
  ContainerTextMoreOne,
} from './styles';
import { useHistory } from 'react-router-dom';
import popupCenter from 'utils/popup';
import { useTheme } from 'styled-components';
import { Col, Row, Spin } from 'antd';

interface TicketDetailProps {
  ticket: CupomType;
  product: ProdutoType;
  toggleModal: (val: boolean) => void;
  produtoConfig: ProdutoConfigType;
}

const TicketDetail: React.FC<TicketDetailProps> = ({
  ticket,
  product,
  toggleModal,
  produtoConfig,
}) => {
  const [loading, setLoading] = useState(true);
  const [cupom, setCupom] = useState<CupomDetail>();
  const { authorization } = useAuth();
  const { products } = useConfig();
  const history = useHistory();
  const theme = useTheme();
  const modalRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState<number>();

  useEffect(() => {
    setWidth(modalRef.current?.clientWidth);
  }, [modalRef]);

  const getDetails = useCallback(async () => {
    setLoading(true);
    const response = await api.get(
      `/cupom/detalhe/${ticket.idTituloPromocao}`,
      {
        headers: { authorization },
      },
    );
    if (response && response.data) {
      setCupom(response.data);
    }
    setLoading(false);
  }, [ticket, authorization]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const getPromotionColor = (colorIndex: number): string => {
    return promotionColors[colorIndex];
  };

  const getDate = (date: string): string => {
    if (!date) return '';
    const dateTime = date.split(' ');
    const aDate = dateTime[0].split('/');
    return `${aDate[0]}/${aDate[1]}`;
  };
  const getTime = (date: string): string => {
    if (!date) return '';
    const dateTime = date.split(' ');
    return dateTime[1];
  };

  const getLastDezena = (sorteio: SorteioType): string | null => {
    if (sorteio.dezenas && sorteio.dezenas.length) {
      return sorteio.dezenas[sorteio.dezenas.length - 1];
    }
    return null;
  };

  const handlePressRegulation = (): void => {
    const configProduto = products.find(p => p.codigo === product.codigo);

    if (configProduto?.promocao?.urlRegulamento)
      popupCenter({
        url: configProduto?.promocao?.urlRegulamento,
        w: 800,
        h: 600,
      });
  };

  const handleMoreOneTicket = (): void => {
    if (product.mostraMarketplace) {
      history.push('/checkout', { product: product });
    } else {
      history.push('/');
    }
    toggleModal(false);
  };

  return (
    <>
      <Wrapper ref={modalRef}>
        <Container>
          {/* {loading && (
            // <ActivityIndicator size="large" color={colors.primaryDark} />
          )} */}

          {/* Regulamento */}
          <ContainerRegulation>
            <ContainerText onClick={handlePressRegulation}>
              <FaExternalLinkAlt size={16} color={theme.corPrimaria} />
              <ButtonText>Regulamento desta promoção</ButtonText>
            </ContainerText>
          </ContainerRegulation>

          {/* GLobo */}
          {!loading &&
            cupom &&
            width &&
            cupom.sorteios &&
            cupom.sorteios.map(s => (
              <div
                key={s.idSorteio}
                style={{
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {!!s.urlImagemPremio && (
                  <Image src={s.urlImagemPremio} width={width} />
                )}
                {s.dezenas && s.dezenas.length && (
                  <>
                    <Dashboard
                      totalDezenas={s.dezenas.length}
                      totalGanhadores={s.ganhadores.length}
                    />
                    <Subtitle>Dezenas sorteadas</Subtitle>
                  </>
                )}
                <DezenaContainer width={width}>
                  {s.dezenas &&
                    s.dezenas.map(dezena => (
                      <Dezena key={dezena} width={width}>
                        {dezena}
                      </Dezena>
                    ))}
                </DezenaContainer>

                {s.ganhadores && s.ganhadores.length > 0 && (
                  <Subtitle>Contemplados</Subtitle>
                )}
                {s.ganhadores &&
                  s.ganhadores.length > 0 &&
                  s.ganhadores.map(ganhador => (
                    <Winner
                      lastDezena={getLastDezena(s)}
                      ganhador={ganhador}
                      key={ganhador.titulo}
                      produtoConfig={produtoConfig}
                      width={width}
                    />
                  ))}
                {cupom.numeroSorte && cupom.numeroSorte[0] && (
                  <Dezenas
                    dezenas={cupom.numeroSorte[0].dezenas}
                    width={width}
                    titulo={formatNumberTicket(cupom.numeroSorte[0].numero)}
                    chance={1}
                    dezenasSorteadas={s.dezenas}
                    realizado={s.realizado}
                    produtoConfig={produtoConfig}
                  />
                )}
                {cupom.numeroSorte && cupom.numeroSorte[1] && (
                  <Dezenas
                    dezenas={cupom.numeroSorte[1].dezenas}
                    width={width}
                    titulo={formatNumberTicket(cupom.numeroSorte[0].numero)}
                    chance={2}
                    dezenasSorteadas={s.dezenas}
                    realizado={s.realizado}
                    produtoConfig={produtoConfig}
                  />
                )}
              </div>
            ))}

          {/* Giro */}
          {!loading &&
            cupom &&
            cupom.giros.map(g => (
              <div
                style={{ justifyContent: 'center', alignItems: 'center' }}
                key={g.codigoTipoSorteio + g.ordem}
              >
                <Image src={g.urlImagemPremio} width={width} />

                {cupom.numeroSorte && cupom.numeroSorte[0] && (
                  <NumeroSorte
                    label="Número da Sorte (1)"
                    numero={cupom.numeroSorte[0].numero}
                    agrupaGiroDaSorte={
                      produtoConfig?.config?.agrupaGiroDaSorte || false
                    }
                  />
                )}
                {cupom.numeroSorte && cupom.numeroSorte[1] && (
                  <NumeroSorte
                    label="Número da Sorte (2)"
                    numero={cupom.numeroSorte[1].numero}
                    agrupaGiroDaSorte={
                      produtoConfig?.config?.agrupaGiroDaSorte || false
                    }
                  />
                )}

                {g.ganhadores && g.ganhadores.length > 0 && (
                  <Subtitle>Contemplados</Subtitle>
                )}

                {g.ganhadores &&
                  width &&
                  g.ganhadores.length > 0 &&
                  g.ganhadores.map(ganhador => (
                    <Winner
                      lastDezena={null}
                      ganhador={ganhador}
                      key={ganhador.titulo}
                      produtoConfig={produtoConfig}
                      width={width}
                    />
                  ))}
              </div>
            ))}

          {/* Número Extra */}
          {!loading &&
            cupom?.numeroExtra &&
            cupom.numerosExtras.map(g => (
              <div
                style={{ justifyContent: 'center', alignItems: 'center' }}
                key={g.codigoTipoSorteio + g.ordem}
              >
                <Image src={g.urlImagemPremio} width={width} />

                {cupom.numeroExtra && (
                  <NumeroSorte
                    label="Número Extra"
                    numero={cupom.numeroExtra}
                    agrupaGiroDaSorte={
                      produtoConfig?.config?.agrupaGiroDaSorte || false
                    }
                  />
                )}

                {g.ganhadores && g.ganhadores.length > 0 && (
                  <Subtitle>Contemplados</Subtitle>
                )}

                {g.ganhadores &&
                  width &&
                  g.ganhadores.length > 0 &&
                  g.ganhadores.map(ganhador => (
                    <Winner
                      lastDezena={null}
                      ganhador={ganhador}
                      extra
                      key={ganhador.titulo}
                      produtoConfig={produtoConfig}
                      width={width}
                    />
                  ))}
              </div>
            ))}

          {!loading && (
            <ContainerRegulation>
              <ContainerTextMoreOne onClick={handleMoreOneTicket}>
                <FaTicketAlt size={16} color="#ffffff" />
                <ButtonText style={{ color: '#ffffff' }}>
                  {`Quero mais um ${
                    produtoConfig.config?.glossario?.certificado || 'Cupom'
                  }`}
                </ButtonText>
              </ContainerTextMoreOne>
            </ContainerRegulation>
          )}

          <div style={{ height: 20 }} />
        </Container>

        {!loading && (
          <Footer>
            <Row
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              {!!product.icone && (
                <Col xs={6}>
                  <ProductImage src={product.icone} />
                </Col>
              )}
              <Col xs={18}>
                <FooterContent>
                  <FooterInfos>
                    <Col md={8} xs={24}>
                      <ContainerDateTimeRow
                        color={getPromotionColor(ticket.promocao.cor)}
                      >
                        <ContainerIcon
                          color={getPromotionColor(ticket.promocao.cor)}
                        >
                          <FaCalendar size={12} color="white" />
                        </ContainerIcon>
                        <ContainerDateTimeText>
                          <LabelDateTime>Data</LabelDateTime>
                          <ValueDateTime>
                            {getDate(ticket.promocao.dataSorteioPrincipal)}
                          </ValueDateTime>
                        </ContainerDateTimeText>
                      </ContainerDateTimeRow>
                    </Col>

                    <Col md={8} xs={24}>
                      <ContainerDateTimeRow
                        color={getPromotionColor(ticket.promocao.cor)}
                      >
                        <ContainerIcon
                          color={getPromotionColor(ticket.promocao.cor)}
                        >
                          <FaClock size={14} color="white" />
                        </ContainerIcon>
                        <ContainerDateTimeText>
                          <LabelDateTime>Horário</LabelDateTime>
                          <ValueDateTime>
                            {getTime(ticket.promocao.dataSorteioPrincipal)}
                          </ValueDateTime>
                        </ContainerDateTimeText>
                      </ContainerDateTimeRow>
                    </Col>

                    <Col md={8} xs={24}>
                      <ContainerDateTimeRow
                        color={getPromotionColor(ticket.promocao.cor)}
                      >
                        <ContainerIcon
                          color={getPromotionColor(ticket.promocao.cor)}
                        >
                          <FaTicketAlt size={14} color="white" />
                        </ContainerIcon>
                        <ContainerDateTimeText>
                          <LabelDateTime>
                            {produtoConfig?.config?.glossario?.numeroDo || 'Nº'}
                            &nbsp;
                          </LabelDateTime>
                          <ValueDateTime>
                            {cupom?.numeroSorte &&
                              cupom?.numeroSorte.length &&
                              formatNumberTicket(cupom?.numeroSorte[0].numero)}
                          </ValueDateTime>
                        </ContainerDateTimeText>
                      </ContainerDateTimeRow>
                    </Col>
                  </FooterInfos>
                </FooterContent>
              </Col>
            </Row>
            <Row>
              <Col xs={24} style={{ textAlign: 'center' }}>
                <SecureCode>Autenticação: {ticket.autenticacao}</SecureCode>
              </Col>
            </Row>
          </Footer>
        )}

        {loading && (
          <Row>
            <Col xs={24} style={{ textAlign: 'center' }}>
              <Spin size="large" />
            </Col>
          </Row>
        )}
      </Wrapper>
    </>
  );
};

export default TicketDetail;
