const apps = [
  {
    title: 'Zona Azul Apcap',
    appId: 'da9c979937f4b719d2c5673f59oi835d',
    code: 'apcap-sp',
  },
  {
    title: 'SAC Apcap',
    appId: '531c21ceecd63807996956dfd567d786',
    code: 'apcap.sac',
  },
  {
    title: 'Apcap SP',
    appId: 'a30cbee34fdf8a75f1166aa15f19a116',
    code: 'apcapsp',
  },
  {
    title: 'Apcap do Bem',
    appId: 'f6c8a7cb9b66baae2ec59befaea52c42',
    code: 'apcap-do-bem',
  },
  {
    title: 'Hiper Cap Litoral',
    appId: 'd59673f49914792b4b7637a66471e366',
    code: 'caplegal-litoral',
  },
  {
    title: 'Cap Legal',
    appId: '0060aee3b5c7db15b2d6c7c77bf29a54',
    code: 'caplegal',
  },
  {
    title: 'Hiper Cap Mogi',
    appId: '3320946f73190f3178075d2df21b8803',
    code: 'hipercap-mogi',
  },
  {
    title: 'Hiper Cap ABC',
    appId: 'b1b5e8323dd7928f4fc58713d283cfe8',
    code: 'hipercap-abc',
  },
  {
    title: 'Vale Cap',
    appId: '55f7be346873b40e4639e15f49ceefcf',
    code: 'valecap',
  },
  {
    title: 'Vida Cap',
    appId: 'e9260dab19b356ff53e68da5345cea6a',
    code: 'vidacap',
  },
  {
    title: 'Hiper Saúde Ribeirão',
    appId: '2a22c90bb4a435ffbe5882e796cd5604',
    code: 'hsauderibeirao',
  },
  {
    title: 'Hiper Saúde Bauru',
    appId: '4c24c2b5c8d5bd15899fe92fe51dc8a2',
    code: 'hsaudebauru',
  },
  { title: 'Sp Cap', appId: '9286dfbed915a721751a3c016ccfb7d6', code: 'spcap' },
  {
    title: 'Saúde Cap',
    appId: '417b3048991c651b902ff56990f53c7c',
    code: 'riopreto',
  },
  {
    title: 'Hospital de Amor',
    appId: '7276ab0e6fde5c736e79f4148b83ecc1',
    code: 'hospitaldeamor',
  },
  {
    title: 'Processa Resultados',
    appId: 'df7ce71f69d7eadecb66058535338f98',
    code: 'processaresultados',
  },
  {
    title: 'Triângulo da Sorte',
    appId: 'b29653e433ba6d9dc36d25e463e6d7ea',
    code: 'triangulodasorte',
  },
  {
    title: 'JF da Sorte',
    appId: 'c811fa255b889acefcdb9b41815c0932',
    code: 'jfdasorte',
  },
  {
    title: 'Trimania Florianopolis e Sul Catarinense',
    appId: '5809efb6794bfd49a5d5a05b3a174010',
    code: 'trifloripa',
  },
  {
    title: 'Megamania Cap',
    appId: '738dfe2b03c5ce8c2037b185eb727093',
    code: 'megamania',
  },
  {
    title: 'Trimania Vale',
    appId: '3209a5414b5aef078a2161f4c78669d9',
    code: 'triblumenau',
  },
  {
    title: 'Trimania Norte',
    appId: '83e86b5408c8fb22cd912bd23c71c824',
    code: 'trijoinville',
  },
  {
    title: 'Centro Oeste Cap',
    appId: 'ce35a9d9c3441bf884d90e00b4f918b8',
    code: 'centrooeste',
  },
  {
    title: 'Caruaru da Sorte',
    appId: '9e34b1da0e177591d24a409a60a341a1',
    code: 'caruarudasorte',
  },
  {
    title: 'Natal Cap',
    appId: '699d1881207609374e4450892298db7b',
    code: 'natalcap',
  },
  {
    title: 'Vale da Sorte',
    appId: 'cbd1fa6344c710a99d7188bafbd45dfb',
    code: 'valedasorte',
  },
  {
    title: 'Hiper Sorte',
    appId: 'aba6883d2ef14d71a235c51a01a8596c',
    code: 'hipersorte',
  },
  {
    title: 'Mineirinha da Sorte',
    appId: 'a87da066442cd0671dc62239cb939f87',
    code: 'mineirinhads',
  },
  {
    title: 'Kariri da Sorte',
    appId: '1c33d2b791d189065ee7f8900cd87af1',
    code: 'kariridasorte',
  },
  {
    title: 'Serra Cap',
    appId: '3642e49eb88310e9f7c493c236b7aa4a',
    code: 'serracap',
  },
  {
    title: 'Megamania Sudoeste',
    appId: 'b4f029dec55b0a697233b474b9824c2a',
    code: 'megasudoeste',
  },
  {
    title: 'Sulcap',
    appId: 'c6558bf29546965727207e5d40bbd4ca',
    code: 'sulcap',
  },
];

export default apps;

export const getApp = (code: string) => {
  return apps.find(a => a.code === code);
};
