import { ExtratoItemType } from 'model/ExtratoItem';
import { SaldoType } from 'model/Saldo';

import api from 'services/api';

interface BalanceData {
  getSaldo(product: string): Promise<SaldoType>;
  getExtrato(dataInicio: string, dataFim: string): Promise<ExtratoItemType[]>;
}

export default function useBalance(authorization: string): BalanceData {
  const getSaldo = async (product: string): Promise<SaldoType> => {
    const response = await api.post(
      '/saldos',
      { produto: product },
      { headers: { authorization } },
    );
    return response.data || {};
  };

  const getExtrato = async (
    dataInicio: string,
    dataFim: string,
  ): Promise<ExtratoItemType[]> => {
    const response = await api.post(
      '/extratos/apcapsp',
      {
        dataInicio,
        dataFim,
      },
      { headers: { authorization } },
    );
    return response.data || [];
  };

  return { getSaldo, getExtrato };
}
