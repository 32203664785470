import { ProdutoConfigType } from 'model/config/ProdutoConfig';
import { CertificadoReservaType } from 'model/Reserva';
import React from 'react';

import {
  Container,
  Titulo,
  TituloWrapper,
  Label,
  DezenasWrapper,
  Dezena,
} from './styles';
interface TicketInfoProps {
  certificado: CertificadoReservaType;
  product: ProdutoConfigType;
  dezenas?: string[] | boolean;
}
const TicketInfo: React.FC<TicketInfoProps> = ({
  certificado,
  product,
  dezenas,
}) => {
  return (
    <Container>
      <TituloWrapper>
        <Label>{product.config?.glossario?.numeroDo}</Label>
        <Titulo>{certificado.numero}</Titulo>
      </TituloWrapper>

      <DezenasWrapper id="wrapper">
        {certificado.dezenas.map(dezena => (
          <Dezena
            widthWrapper={
              document.getElementById('wrapper')?.offsetWidth || 360
            }
            selected={
              (dezenas &&
                typeof dezenas === 'object' &&
                dezenas.includes(dezena)) ||
              false
            }
          >
            {dezena}
          </Dezena>
        ))}
      </DezenasWrapper>
    </Container>
  );
};

export default TicketInfo;
