import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import AppProvider from './hooks';

import Routes from './routes';
import { store } from './store';

const App: React.FC = () => {
  return (
    <Router>
      <Provider store={store as any}>
        <AppProvider>
          <Routes />
        </AppProvider>
      </Provider>
    </Router>
  );
};

export default App;
