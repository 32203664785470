/* eslint-disable no-template-curly-in-string */
import { Button, Form, Input, message, PageHeader, Spin } from 'antd';
import React, { ChangeEvent } from 'react';
import { FaUserFriends } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import useFriend from './useFriend';
import { MaskedInput } from 'antd-mask-input';
import { onlyNumbers } from 'utils/StringHelper';
import { isValidCpf } from '@brazilian-utils/is-valid-cpf';
import { isValid, parse } from 'date-fns';

import 'moment/locale/pt-br';

const validateMessages = {
  required: '${label} é obrigatório!',
  types: {
    number: '${label} não é um número válido!',
    email: '${label} não é um e-mail válido!',
  },
};

interface FriendProps {
  isModal?: boolean;
  onSave?: (cpf: string) => void;
}

const Friend: React.FC<FriendProps> = ({ isModal, onSave }) => {
  const theme = useTheme();
  const [form] = Form.useForm();
  const { saveFriend, loading: loadingSave } = useFriend();

  const onFinish = async (values: any) => {
    const cpf = await saveFriend({ ...values });
    if (cpf) {
      message.success('Amigo inserido com sucesso');
      form.resetFields();
      if (onSave) {
        onSave(cpf);
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {};

  const checkCPF = (rule: any, value: any) => {
    const valCpf = value ? onlyNumbers(value) : value;
    if (valCpf && valCpf.length < 11) {
      return Promise.reject('CPF inválido');
    } else if (!isValidCpf(valCpf)) {
      return Promise.reject('CPF inválido');
    } else {
      return Promise.resolve();
    }
  };

  const checkCEP = (rule: any, value: any) => {
    if (value && onlyNumbers(value).length === 8) {
      return Promise.resolve();
    } else {
      return Promise.reject('CEP inválido');
    }
  };

  const checkTelefone = (rule: any, value: any) => {
    if (value && onlyNumbers(value).length === 11) {
      return Promise.resolve();
    } else {
      return Promise.reject('Nº telefone inválido');
    }
  };

  const checkBirthDate = (rule: any, value: any) => {
    if (isValid(parse(value, 'dd/MM/yyyy', new Date()))) {
      return Promise.resolve();
    } else {
      return Promise.reject('Data de Nascimento inválida');
    }
  };

  return (
    <>
      {!isModal && (
        <PageHeader
          backIcon={false}
          title={
            <>
              <h2 style={{ color: theme.corPrimaria }}>
                <FaUserFriends size={20} /> Novo Amigo
              </h2>
            </>
          }
        />
      )}
      {loadingSave && <Spin size="large" />}

      <Form
        layout="vertical"
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
        style={{ marginTop: 20 }}
        form={form}
      >
        <Form.Item
          name="cpf"
          label="CPF do amigo"
          rules={[{ required: true, validator: checkCPF }]}
        >
          <MaskedInput
            mask="111.111.111-11"
            name="card"
            size="large"
            style={{ fontSize: 40 }}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          name="nomeCompleto"
          label="Nome do amigo"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email do amigo"
          rules={[{ required: true, type: 'email' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="dataNascimento"
          label="Data Nascimento"
          rules={[{ required: true, validator: checkBirthDate }]}
        >
          <MaskedInput mask="11/11/1111" placeholder="dd/mm/aaaa" />
        </Form.Item>

        <Form.Item
          name="celular"
          label="Nº Telefone com DDD do amigo"
          rules={[{ required: true, validator: checkTelefone }]}
        >
          <MaskedInput mask="(11) 11111-1111" />
        </Form.Item>

        <Form.Item
          name="cep"
          label="CEP"
          rules={[{ required: true, validator: checkCEP }]}
        >
          <MaskedInput mask="11111-111" />
        </Form.Item>

        <Form.Item>
          <Button disabled={loadingSave} type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Friend;
