import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ProdutoConfigType } from 'model/config/ProdutoConfig';
import Banner from './components/Banner';
import {
  Container,
  Title,
  RadioButton,
  RadioButtonContent,
  ButtonWrapper,
} from './styles';
import Totals from './components/Totals';
import {
  Radio,
  Row,
  Col,
  Checkbox,
  Modal,
  Button,
  notification,
  Spin,
  Typography,
  Form,
} from 'antd';
import { useAuth } from 'hooks/auth';
import {
  FaUserCircle as User,
  FaExternalLinkAlt,
  FaCreditCard,
} from 'react-icons/fa';
import { TiDocumentText } from 'react-icons/ti';

import PaymentMethods from './components/PaymentMethods';
import { RadioChangeEvent } from 'antd/lib/radio';
import PaymentReview from './components/PaymentReview';
import PurchaseFriend from './components/PurchaseFriend';
import TicketSelect from './components/TicketSelect';
import { AmigoType } from 'model/Amigo';
// import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { CardType } from 'model/card/Card';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { MaskedInput } from 'antd-mask-input';
import { onlyNumbers } from 'utils/StringHelper';
import api from 'services/api';
import popupCenter from 'utils/popup';
import { useHistory } from 'react-router-dom';
import { ReservaType } from 'model/Reserva';
import ReCAPTCHA from 'react-google-recaptcha';

const Label = ({
  icon,
  text,
  color,
  ...rest
}: {
  icon: JSX.Element;
  text: string;
  color?: string;
}) => {
  return (
    <span {...rest} style={{ color }}>
      {icon} <span>&nbsp;{text}</span>
    </span>
  );
};

const Checkout: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const location = useLocation<any>();
  const { product }: { product: ProdutoConfigType } = location.state;
  const [modalTermsVisible, setModalTermsVisible] = useState(false);
  const [modalCvvVisible, setModalCvvVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buyTo, setBuyTo] = useState();
  const [cvv, setCvv] = useState<string>();
  const [reservas, setReservas] = useState<ReservaType[]>([]);
  const [agree, setAgree] = useState<boolean>(false);
  const [reserva, setReserva] = useState<boolean>(false);
  const [payment, setPayment] = useState<CardType | undefined>();
  const [useSaldo, setUseSaldo] = useState(false);
  const [friend, setFriend] = useState<AmigoType>();
  const [quantity, setQuantity] = useState(1);
  // const lastPaymentUsed = useSelector(({ user }: any) => user.lastPaymentUsed);
  // const localPayments = useSelector(({ user }: any) => user.payments) || [];

  const { user, authorization } = useAuth();

  const handleOpenTerms = (): void => {
    if (product.promocao?.urlRegulamento) {
      // setModalTermsVisible(true);
      popupCenter({
        url: product.promocao?.urlRegulamento,
        w: 800,
        h: 600,
      });
    }
  };

  const handleModalTermsOk = useCallback((): void => {
    setModalTermsVisible(false);
  }, []);

  const handleModalTermsCancel = useCallback((): void => {
    setModalTermsVisible(false);
  }, []);

  const handleBuyTo = useCallback((e: RadioChangeEvent) => {
    setBuyTo(e.target.value);
    if (e.target.value === 'me') {
      setFriend(undefined);
    }
  }, []);

  const handleChangeAgree = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    setAgree(checked);
  };

  const handleChangeCvv = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      setCvv(onlyNumbers(value));
    },
    [],
  );

  const handleCloseSuccess = useCallback(
    (redirect: boolean) => {
      if (redirect) {
        history.replace('/tickets', { product: product });
      } else {
        history.replace('/');
      }
    },
    [history, product],
  );

  const cancelReserva = useCallback(async (): Promise<void> => {
    setLoading(true);
    await api.post(
      '/certificadoDeContribuicao/reserva/cancelar',
      { codProduto: product.codigo },
      {
        headers: { authorization },
      },
    );
    setLoading(false);
    setReservas([]);
  }, [authorization, product.codigo]);

  const handleClickCvv = useCallback(() => {
    if (
      payment?.tipoCartao === 2 &&
      (!cvv || onlyNumbers(cvv) === '' || cvv.length < 3)
    ) {
      notification.error({
        message: (
          <h3>
            <span role="img" aria-label="Emoji">
              😬
            </span>{' '}
            Atenção
          </h3>
        ),
        description: 'Você precisa informar o CVV com no mínimo 3 dígitos',
      });
    } else {
      setModalCvvVisible(false);
      if (product && product.promocao) {
        const compra = {
          pessoa: friend,
          codProduto: product?.codigo,
          quantidade: quantity,
          valor: quantity * (product.promocao.valorProduto || 0),
          utilizaSaldoCarteira: useSaldo,
          cvv: useSaldo ? undefined : cvv,
          reserva,
          idFormaDePagamento:
            payment && payment.idFormaDePagamento
              ? payment.idFormaDePagamento
              : undefined,
        };
        setLoading(true);
        api
          .post('/certificadoDeContribuicao/compra', compra, {
            headers: { authorization },
          })
          .then(response => {
            if (response.data && response.data?.redirect) {
              popupCenter({ url: response.data.redirect, w: 800, h: 600 });
              history.replace('/');
            } else {
              Modal.success({
                title: 'Compra realizada com sucesso',
                onOk: () => handleCloseSuccess(!friend),
                onCancel: () => handleCloseSuccess(false),
                okButtonProps: {
                  name: !!friend
                    ? 'Ver ' + product?.config?.glossario?.certificados
                    : 'OK',
                },
                content: (
                  <>
                    {response &&
                      response.data &&
                      response.data.brinde &&
                      response.data.brinde.mensagem && (
                        <h2>{response.data.brinde.mensagem}</h2>
                      )}
                    {friend && (
                      <h3>{product.config?.glossario?.textoSucessoAmigo}</h3>
                    )}
                    {!friend && (
                      <h3>{product.config?.glossario?.textoSucesso}</h3>
                    )}
                  </>
                ),
              });
            }
          })
          .catch(err => {
            // setStatusProcess('error');
            cancelReserva();
            notification.error({
              message: (
                <h3>
                  <span role="img" aria-label="Emoji">
                    😬
                  </span>{' '}
                  Atenção
                </h3>
              ),
              description:
                err.response?.data?.mensagem ||
                'Algo inexperado ocorreu, tente novamente',
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [
    cvv,
    product,
    reserva,
    friend,
    payment,
    quantity,
    authorization,
    history,
    cancelReserva,
    handleCloseSuccess,
    useSaldo,
  ]);

  const handleSubmit = useCallback(() => {
    if (recaptchaRef && recaptchaRef.current) recaptchaRef.current.execute();
    const errors = [];
    if (!agree) errors.push('⚠️ Concordar com os termos');
    if (!payment && !useSaldo) errors.push('⚠️ Escolher a forma de pagamento');
    if (!friend && buyTo === 'friend')
      errors.push('⚠️ Escolher o amigo para comprar');
    if (errors.length > 0) {
      notification.error({
        message: (
          <h3>
            <span role="img" aria-label="Emoji">
              😬
            </span>{' '}
            Atenção, você precisa:
          </h3>
        ),
        description: (
          <div>
            {errors.map(err => (
              <p key={err}>{err}</p>
            ))}
          </div>
        ),
      });
    } else {
      if (payment?.tipoCartao === 2) {
        setModalCvvVisible(true);
      } else {
        handleClickCvv();
      }
    }
  }, [agree, buyTo, friend, payment, handleClickCvv, useSaldo]);

  useEffect(() => {
    if (payment && payment.tipoCartao === 1 && reserva) {
      cancelReserva();
    }
  }, [payment, reserva, cancelReserva]);

  interface Windows extends Window {
    recaptchaOptions?: any;
  }

  useEffect(() => {
    const w: Windows = window;
    w.recaptchaOptions = {
      useRecaptchaNet: true,
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Spin spinning={loading} delay={200} size="large">
      <Row>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}>
          <Banner product={product} />

          <Container>
            <Totals
              product={product}
              setQuantity={setQuantity}
              reservas={reservas}
              cancelReservas={cancelReserva}
            />

            <Title>{product?.config?.glossario?.adquira || 'ADQUIRA'}</Title>

            <Radio.Group
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginBottom: 20,
              }}
              size="large"
              buttonStyle="solid"
              onChange={handleBuyTo}
              defaultValue="me"
            >
              <RadioButton value="me">
                <RadioButtonContent>
                  {user && (
                    <img
                      src={user.urlImageProfile}
                      alt={user.Nome}
                      style={{ width: 30, borderRadius: '100%' }}
                    />
                  )}
                  <span> PRA MIM</span>
                </RadioButtonContent>
              </RadioButton>
              <RadioButton value="friend">
                <RadioButtonContent>
                  <span>PRA UM AMIGO</span>
                  <User size={30} />
                </RadioButtonContent>
              </RadioButton>
            </Radio.Group>

            {buyTo === 'friend' && (
              <PurchaseFriend product={product} setFriend={setFriend} />
            )}

            <PaymentMethods
              product={product}
              setPayment={setPayment}
              setUseSaldo={setUseSaldo}
            />

            {payment && payment.tipoCartao === 2 && (
              <TicketSelect
                product={product}
                quantity={quantity}
                withReserva={setReserva}
                setReservas={setReservas}
                reservas={reservas}
              />
            )}

            <Form layout="vertical" style={{ width: '90%' }}>
              <Form.Item
                label={
                  <Label
                    icon={<TiDocumentText size={18} />}
                    text="Termos e Condições"
                    color={
                      product.config?.web?.corPrimaria || theme.corPrimaria
                    }
                  />
                }
                name="layout"
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Checkbox onChange={handleChangeAgree}></Checkbox>
                  <Typography.Paragraph
                    ellipsis={{
                      rows: 1,
                      expandable: true,
                      symbol: 'Ver mais',
                    }}
                    style={{ flex: 0.8 }}
                  >
                    {product?.config?.glossario?.aceite}
                  </Typography.Paragraph>
                  <FaExternalLinkAlt
                    style={{ cursor: 'pointer' }}
                    onClick={handleOpenTerms}
                    size={18}
                    color={product.config?.web?.corPrimaria}
                  />
                </div>
              </Form.Item>
            </Form>
          </Container>

          <PaymentReview
            product={product}
            friend={friend}
            payment={payment}
            quantity={quantity}
            useSaldo={useSaldo}
          />
          <ButtonWrapper>
            <Button
              id="submit"
              onClick={handleSubmit}
              type="primary"
              loading={false}
              style={{
                borderRadius: 10,
                borderColor: 'transparent',
                backgroundColor: theme.corPrimaria,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: 20,
                padding: '24px 40px',
              }}
            >
              COMPRAR
            </Button>
          </ButtonWrapper>
        </Col>
      </Row>

      <Modal
        title={
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <FaCreditCard color={theme.corPrimaria} size={24} />
            &nbsp;Digite o CVV
          </p>
        }
        visible={modalCvvVisible}
        onOk={handleClickCvv}
        onCancel={() => setModalCvvVisible(false)}
      >
        <MaskedInput
          mask="1111"
          size="large"
          min={1}
          max={9999}
          autoFocus
          required
          placeholderChar=" "
          onChange={handleChangeCvv}
        />
      </Modal>

      <Modal
        title="Termos e Condições"
        visible={modalTermsVisible}
        onOk={handleModalTermsOk}
        onCancel={handleModalTermsCancel}
        width="100%"
      >
        <object
          id="termos"
          type="application/pdf"
          data={product.promocao?.urlRegulamento}
          width="100%"
          height={window.innerHeight / 1.5}
          aria-label="Termos"
        ></object>
      </Modal>

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6Lc_MdUZAAAAANofSRvmNkV91qWXLVolIu2bLgya"
      />
    </Spin>
  );
};

export default Checkout;
