import React, { useMemo } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';
import GlobalStyle from '../styles/global';
import { ThemeProvider } from 'styled-components';
import { subdomain } from '../services/helper';
import { useConfig } from '../hooks/config';
import { WebConfigType } from 'model/config/ConfigDoProduto';
import Page404 from 'pages/Page404';
import ProductList from 'pages/ProductList';
import Checkout from 'pages/Checkout';
import Tickets from 'pages/Tickets';
import TicketsProductList from 'pages/Tickets/ProductList';
import PaymentMethods from 'pages/PaymentMethods';
import PaymentMethod from 'pages/PaymentMethod';
import ListBankSlip from 'pages/ListBankSlip';
import Balance from 'pages/Balance';
import UserData from 'pages/UserData';
import Help from 'pages/Help';
import ForgotPassword from 'pages/ForgotPassword';
import AddBankSlip from 'pages/AddBankSlip';
import Friends from 'pages/Friends';
import Friend from 'pages/Friend';

const Routes: React.FC = () => {
  const { products } = useConfig();

  const colors: WebConfigType = useMemo(() => {
    let colors;
    if (products && products.length) {
      const prod = products.findIndex(p => p.codigo === subdomain);
      if (products[prod < 0 ? 0 : prod].config) {
        const config = products[prod < 0 ? 0 : prod].config;
        colors = config && config.web;
      }
    }

    return (
      colors || {
        corPrimaria: '#ed6e1f',
        corSecundaria: '#f6f6f6',
        corTextoPrimaria: '#00000097',
        corTextoSecundaria: '#6B6C6C62',
      }
    );
  }, [products]);

  return (
    <ThemeProvider theme={colors}>
      <BrowserRouter basename={subdomain}>
        <Switch>
          <Route
            path="/tickets-products"
            component={TicketsProductList}
            isPrivate
            exact
          />
          <Route
            path="/payment-method"
            exact
            component={PaymentMethod}
            isPrivate
          />
          <Route
            path="/payment-methods"
            exact
            component={PaymentMethods}
            isPrivate
          />
          <Route
            path="/list-bankslip"
            exact
            component={ListBankSlip}
            isPrivate
          />
          <Route path="/friends" exact component={Friends} isPrivate />
          <Route path="/friend" exact component={Friend} isPrivate />
          <Route path="/add-balance" exact component={AddBankSlip} isPrivate />
          <Route path="/help" exact component={Help} isPrivate />
          <Route path="/userdata" exact component={UserData} isPrivate />
          <Route path="/balance" exact component={Balance} isPrivate />
          <Route path="/tickets" exact component={Tickets} isPrivate />
          <Route path="/dashboard" component={Dashboard} isPrivate />
          <Route path="/checkout" component={Checkout} isPrivate />
          <Route path="/recover-password" component={ForgotPassword} />
          <Route path="/login" component={SignIn} />
          <Route path="404" component={Page404} />
          <Route path="/" exact component={ProductList} isPrivate />
          <Route path="*" component={Page404} isPrivate />
        </Switch>
      </BrowserRouter>
      <GlobalStyle />
    </ThemeProvider>
  );
};

export default Routes;
