import React from 'react';
import { AnimateOnChange } from 'react-animation';

import {
  Container,
  DezenasSorteadas,
  Ganhadores,
  DezenasText,
  GanhadoresText,
  GroupContainer,
  DezenasTitle,
  GanhadoresTitle,
} from './styles';

interface DashboardProps {
  totalDezenas: number;
  totalGanhadores: number;
}

const Dashboard: React.FC<DashboardProps> = ({
  totalDezenas,
  totalGanhadores,
}) => {
  return (
    <Container>
      <AnimateOnChange animationIn="slideInLeft">
        <GroupContainer>
          <DezenasSorteadas>
            <DezenasText>{totalDezenas}</DezenasText>
          </DezenasSorteadas>
          <DezenasTitle>DEZENAS SORTEADAS</DezenasTitle>
        </GroupContainer>
      </AnimateOnChange>

      <AnimateOnChange animationIn="slideInRight">
        <GroupContainer>
          <Ganhadores>
            <GanhadoresText>{totalGanhadores}</GanhadoresText>
          </Ganhadores>
          <GanhadoresTitle>GANHADORES</GanhadoresTitle>
        </GroupContainer>
      </AnimateOnChange>
    </Container>
  );
};

export default Dashboard;
