import React, { useCallback } from 'react';
import { FaClock } from 'react-icons/fa';
import { ProdutoConfigType } from 'model/config/ProdutoConfig';

import Dezena from './Dezena';
import {
  Container,
  DezenaContainer,
  Header,
  Footer,
  HeaderText,
  FooterText,
  Wrapper,
} from './styles';

interface DezenasProps {
  dezenas: string[];
  width: number;
  titulo: string;
  chance: number;
  dezenasSorteadas?: string[];
  // aguardando: boolean | null;
  realizado: boolean;
  produtoConfig: ProdutoConfigType;
}

const Dezenas: React.FC<DezenasProps> = ({
  dezenas,
  width,
  titulo,
  chance,
  dezenasSorteadas,
  realizado,
  produtoConfig,
}) => {
  const goodDozens = useCallback(() => {
    let total = 0;
    dezenas.forEach(dozen => {
      if (dezenasSorteadas && dezenasSorteadas?.includes(dozen)) {
        total += 1;
      }
    });
    return total;
  }, [dezenasSorteadas, dezenas]);

  return (
    <Wrapper>
      <Container width={width}>
        <Header>
          <HeaderText>Chance ({chance})</HeaderText>
          <HeaderText>
            Nº do Título
            <HeaderText style={{ fontSize: 24, opacity: 1 }}>
              {' '}
              {titulo}
            </HeaderText>
          </HeaderText>
        </Header>
        <DezenaContainer
          width={width}
          vertical={produtoConfig.config?.orientacaoDezenas === 'vertical'}
        >
          {dezenas &&
            dezenas.map(dezena => (
              <Dezena
                key={dezena}
                dezena={dezena}
                width={width}
                realizado={realizado}
                dezenasSorteadas={dezenasSorteadas}
              />
            ))}
        </DezenaContainer>
        <Footer>
          {dezenasSorteadas && dezenasSorteadas.length && (
            <FooterText>Você acertou {goodDozens()} dezenas</FooterText>
          )}
          {!dezenasSorteadas && (
            <FooterText>
              <FaClock size={24} />
              &nbsp; Aguardando Sorteio
            </FooterText>
          )}
        </Footer>
      </Container>
    </Wrapper>
  );
};

export default Dezenas;
