import { useConfig } from 'hooks/config';
import { ProdutoConfigType } from 'model/config/ProdutoConfig';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Row, Col, Button, PageHeader } from 'antd';

import { Container, Title, Value } from './styles';
import { toCurrency } from 'utils/StringHelper';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { FaTicketAlt } from 'react-icons/fa';

const { Meta } = Card;

const ProductList: React.FC = () => {
  const { products } = useConfig();

  const [produtos, setProdutos] = useState<ProdutoConfigType[]>([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const theme = useTheme();

  const getProdutos = useCallback(async () => {
    setLoading(true);

    if (products) {
      setProdutos(products.filter(p => p.usadoComoBrinde));
    }

    setLoading(false);

    if (products.length === 1) {
      history.push('/checkout', { product: products[0] });
    }
  }, [products, history]);

  useEffect(() => {
    getProdutos();
  }, [getProdutos]);

  interface ItemProps {
    product: ProdutoConfigType;
  }

  const Item: React.FC<ItemProps> = ({
    product,
  }: {
    product: ProdutoConfigType;
  }) => {
    return (
      <>
        <Col style={{ marginBottom: 40 }}>
          <Card
            hoverable
            onClick={() => history.push('/checkout', { product: product })}
            style={{ width: 300 }}
            cover={
              <img
                alt={product.titulo}
                src={product.icone}
                height={300}
                style={{ padding: 10 }}
              />
            }
            actions={[
              <div style={{ paddingRight: 20, paddingLeft: 20 }}>
                <Button
                  type="primary"
                  block
                  shape="round"
                  size="large"
                  style={{
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                  onClick={() =>
                    history.push('/checkout', { product: product })
                  }
                >
                  {product.config?.glossario?.adquira}
                </Button>
              </div>,
            ]}
          >
            <Meta
              title={
                <Title style={{ color: product.config?.web?.corPrimaria }}>
                  {product.titulo}
                </Title>
              }
              description={
                <Value>{toCurrency(product.promocao?.valorProduto || 0)}</Value>
              }
            />
          </Card>
        </Col>
      </>
    );
  };

  const renderMain = () => {
    if (!loading && produtos && produtos.length) {
      return produtos.map(product => (
        <Item key={product.codigo} product={product} />
      ));
    }
  };

  return (
    <>
      <PageHeader
        backIcon={false}
        title={
          <>
            <h2
              style={{
                color: theme.corPrimaria,
                whiteSpace: 'pre-wrap',
                textAlign: 'center',
              }}
            >
              <FaTicketAlt size={20} /> Selecione o produto para comprar
            </h2>
          </>
        }
      />
      <Container>
        <Card>
          <Row style={{ justifyContent: 'center' }} gutter={16}>
            {renderMain()}
          </Row>
        </Card>
      </Container>
    </>
  );
};

export default ProductList;
