interface ActionReturn {
  type: string;
  payload: any;
}

export function updateLastPaymentUsed(lastPaymentUsed: string): ActionReturn {
  return {
    type: '@user/UPDATE_LAST_PAYMENT_USED',
    payload: { lastPaymentUsed },
  };
}

export function updatePerson(person: any): ActionReturn {
  return {
    type: '@user/UPDATE_PERSON',
    payload: { person },
  };
}

export function updatePayments(payments: any): ActionReturn {
  return {
    type: '@user/UPDATE_PAYMENTS',
    payload: { payments },
  };
}
