import React, {
  useRef,
  useCallback,
  useMemo,
  ChangeEvent,
  useState,
  useEffect,
} from 'react';
import { FiLogIn } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { isValidCpf } from '@brazilian-utils/is-valid-cpf';
import { useAuth } from '../../hooks/auth';
import { subdomain } from '../../services/helper';

import { Form, Input, Button, notification } from 'antd';

import { Container, Content, AnimationContainer, Background } from './styles';
import { useConfig } from '../../hooks/config';
import { MaskedInput } from 'antd-mask-input';
import ReCAPTCHA from 'react-google-recaptcha';
import { onlyNumbers } from 'utils/StringHelper';
import api from 'services/api';
import { useForm } from 'antd/lib/form/Form';

interface SignInFormData {
  cpf: string;
  senha: string;
}

const SignIn: React.FC = () => {
  const { products } = useConfig();
  const { signIn, person } = useAuth();
  const history = useHistory();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [validCpf, setValidCpf] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setPerson } = useAuth();
  const [form] = useForm();
  const product = useMemo(() => {
    if (products && products.length) {
      const prod = products.findIndex(p => p.codigo === subdomain) || 0;
      return products[prod < 0 ? 0 : prod];
    }
  }, [products]);

  interface Windows extends Window {
    recaptchaOptions?: any;
  }

  useEffect(() => {
    const w: Windows = window;
    w.recaptchaOptions = {
      useRecaptchaNet: true,
    };
    // eslint-disable-next-line
  }, []);

  const loadPessoa = useCallback(
    (cpf: string): void => {
      setLoading(true);
      api
        .get(`/pessoa?cpf=${cpf}`)
        .then(res => {
          if (res && res.data) {
            setValidCpf(true);
            setPerson(res.data);
          } else {
            setPerson(undefined);
          }
          setLoading(false);
        })
        .catch(err => {
          notification.error({
            message: 'Atenção 😬',
            description: err.response.data.mensagem,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [setPerson],
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const text = event.target.value;
    const valCpf = onlyNumbers(text);
    if (isValidCpf(valCpf)) {
      loadPessoa(valCpf);
    } else if (valCpf.length === 11) {
      notification.error({
        message: 'Atenção 😬',
        description: 'CPF informado não é válido',
      });
    }
  };

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      if (recaptchaRef && recaptchaRef.current) recaptchaRef.current.execute();
      try {
        await signIn({
          cpf: onlyNumbers(data.cpf),
          senha: data.senha,
        });

        // history.push('/dashboard');
      } catch (err) {
        notification.error({
          message: 'Ops! 😬',
          description:
            err.data.mensagem ||
            'Ocorreu um erro ao fazer login, confira as credenciais.',
        });
      }
    },
    [signIn],
  );

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Container>
      <Content>
        <AnimationContainer>
          {!loading && product && product.icone && (
            <img
              src={product.icone}
              alt={product.nome}
              width={'30%'}
              style={{ maxWidth: 600 }}
            />
          )}

          <Form
            name="basic"
            onSubmitCapture={e => e.preventDefault()}
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            form={form}
          >
            <Form.Item
              label="CPF"
              name="cpf"
              rules={[
                { required: true, message: 'Por favor, digite seu CPF.' },
              ]}
            >
              <MaskedInput
                mask="111.111.111-11"
                name="card"
                size="large"
                style={{ fontSize: 40 }}
                onChange={handleChange}
              />
            </Form.Item>

            <Form.Item
              label="Senha"
              name="senha"
              rules={[
                {
                  required: true,
                  message: 'Por favor, digite sua senha.',
                  pattern: /^[0-9]{4}$/,
                },
              ]}
            >
              <Input.Password
                size="large"
                maxLength={4}
                className="input-password"
              />
            </Form.Item>

            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey="6Lc_MdUZAAAAANofSRvmNkV91qWXLVolIu2bLgya"
            />

            <Form.Item style={{ textAlign: 'center' }}>
              <Button
                type="ghost"
                className="background-theme color-theme"
                htmlType="button"
                onClick={() => form.submit()}
                size="large"
                shape="round"
                style={{
                  justifyContent: 'center',
                  minWidth: 200,
                  minHeight: 60,
                }}
                disabled={!validCpf}
              >
                <FiLogIn />
                &nbsp; Entrar
              </Button>
            </Form.Item>
          </Form>
          <Button
            disabled={!person}
            type="link"
            htmlType="button"
            onClick={() => history.push('/recover-password')}
            title={!person ? 'Digite seu CPF primeiro' : ''}
          >
            Esqueci minha senha
          </Button>
        </AnimationContainer>
      </Content>

      <Background />
    </Container>
  );
};

export default SignIn;
