import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createEncryptor from 'redux-persist-transform-encrypt';

const encryptor: any = createEncryptor({
  secretKey:
    '87204b12851dc43108e5a65728e8c5a6cde4411658612a62717350a2e184c4994f97e4e1dd2758c1aab33d816a6e1ab308f51438e1b95f15a06213795f458122',
});

export default (reducers: any): any => {
  const persistedReducer = persistReducer(
    {
      key: 'apcapweb__',
      storage,
      whitelist: ['user', 'layout'],
      transforms: [encryptor],
    },
    reducers,
  );
  return persistedReducer;
};
