/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  List,
  message,
  PageHeader,
  Popconfirm,
  Skeleton,
  Spin,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAuth } from 'hooks/auth';
import api from 'services/api';
import { CardAvailableType } from 'model/card/Card';
import PaymentIcon from 'react-payment-icons';
import { Container } from './styles';
import { FaCreditCard } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';

const PaymentMethods: React.FC = () => {
  const { authorization } = useAuth();
  const [cardAvailable, setCardAvailable] = useState<CardAvailableType>();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const history = useHistory();

  const loadCards = useCallback(async (): Promise<void> => {
    setLoading(true);
    const response = await api.get('/formasPagamento/disponiveis', {
      headers: { authorization },
    });

    if (response && response.data) {
      setCardAvailable(response.data);
    }
    setLoading(false);
  }, [authorization]);

  useEffect(() => {
    loadCards();
  }, [loadCards]);

  const handleDelete = async (id: number): Promise<void> => {
    setLoading(true);
    const response = await api.get(`/formasPagamento/${id}`, {
      headers: { authorization },
    });

    if (response && response.data) {
      message.success('Cartão excluído com sucesso');
      loadCards();
    }
    setLoading(false);
  };

  const handleNew = () => {
    history.push('/payment-method');
  };

  return (
    <Container>
      <PageHeader
        backIcon={false}
        title={
          <>
            <h2 style={{ color: theme.corPrimaria }}>
              <FaCreditCard size={20} /> Meus Cartões
            </h2>
          </>
        }
        extra={[
          <Button
            onClick={handleNew}
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
          >
            Novo
          </Button>,
        ]}
      />
      {cardAvailable && cardAvailable.cartao_credito_debito.lista && (
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={cardAvailable.cartao_credito_debito.lista}
          renderItem={item => (
            <List.Item
              key={item.idFormaDePagamento}
              style={{
                backgroundColor: 'white',
                padding: 10,
                textAlign: 'left',
              }}
              actions={[
                <Popconfirm
                  title="Deseja Realmente excluir ?"
                  onConfirm={() => handleDelete(item.idFormaDePagamento)}
                  onCancel={() => null}
                  okText="Sim"
                  cancelText="Não"
                >
                  <a href="#">Excluir</a>
                </Popconfirm>,
              ]}
            >
              <Skeleton avatar title={false} loading={loading} active>
                <List.Item.Meta
                  avatar={
                    <PaymentIcon
                      id={
                        String(item.bandeiraCartao).toLowerCase() !==
                        'hipercard'
                          ? String(item.bandeiraCartao).toLowerCase()
                          : 'generic'
                      }
                      style={{ marginRight: 10, width: 50 }}
                      className="payment-icon"
                    />
                  }
                  title={item.numeroCartao}
                  description={
                    item.bandeiraCartao +
                    ' | Cartão de ' +
                    (item.tipoCartao === 1 ? ' Débito' : ' Crédito')
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      )}
      {loading && <Spin size="large" />}
    </Container>
  );
};

export default PaymentMethods;
