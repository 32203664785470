import React from 'react';

import { Container, NumberContent, Number, Space, Label } from './styles';

interface NumeroSorteProps {
  numero: string;
  agrupaGiroDaSorte: boolean;
  label: string;
}
const NumeroSorte: React.FC<NumeroSorteProps> = ({
  numero,
  agrupaGiroDaSorte,
  label,
}) => {
  const localNumber = (agrupaGiroDaSorte
    ? numero
    : numero.replace(/(.{2})/g, '$1 ')
  ).split('');

  return (
    <>
      <Label>{label}</Label>
      <Container>
        {localNumber.map((number: string, index) => {
          const { length } = localNumber;
          const key = Math.random();
          if (number !== ' ') {
            const first = index === 0 || localNumber[index - 1] === ' ';
            const last = index + 1 === length || localNumber[index + 1] === ' ';
            return (
              <NumberContent key={key} first={first} last={last}>
                <Number>{number}</Number>
              </NumberContent>
            );
          }
          return index + 1 !== length && <Space key={key} />;
        })}
      </Container>
    </>
  );
};

export default NumeroSorte;
