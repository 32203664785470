import { AnimateOnChange } from 'react-animation';

import styled from 'styled-components';

export const Container = styled.div`
  justify-content: flex-start;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const TicketsInProgress = styled(AnimateOnChange)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  margin-top: 10px;
  background-color: ${props => props.theme.corPrimaria};
  border-left-width: 4px;
  border-left-color: ${props => props.theme.corPrimaria};
  display: flex;
`;

export const TicketsInProgressText = styled.span`
  color: '#ffffff';
  font-size: 16px;
  text-align: center;
  margin-left: 10px;
`;
export const TicketsInProgressTextBold = styled.span``;
