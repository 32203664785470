import styled from 'styled-components';
import { Radio } from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  background-color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  && .ant-radio-button-wrapper:first-child {
    border-radius: 20px 0 0 20px;
  }

  && .ant-radio-button-wrapper:last-child {
    border-radius: 0 20px 20px 0;
  }
`;

export const Title = styled.h2``;

export const RadioButton = styled(Radio.Button)`
  width: 50%;
  @media screen and (min-width: 800px) {
    width: 300px;
  }
`;

export const RadioButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
`;
