/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';
import { Typography, Modal as ModalA } from 'antd';
import { Card } from 'antd';

export const Modal = styled(ModalA)`
  && .ant-result {
    padding: 0;
  }
`;

export const Container = styled.div`
  background-color: white;
  padding: 20px;
`;

export const Image = styled.img`
  border-radius: 10px;
  height: 200px;
  margin-bottom: 10px;
`;

export const Title = styled.h1`
  font-size: 22px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.corPrimaria};
  margin-bottom: 5px;
`;

export const Tip = styled.h2`
  color: ${props => props.theme.corTextoPrimaria};
  margin-bottom: 30px;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Boxes = styled(Card)`
  && .ant-card-body {
    padding: 0px;
  }
`;

interface BoxProps {
  active: boolean;
}

export const Box = styled(Card.Grid)<BoxProps>`
  background-color: ${props =>
    props.active ? props => props.theme.corPrimaria : 'white'};
  border-width: 2px;
  border-color: ${props => props.theme.corPrimaria};
  border-radius: 10px;
  width: 50%;
  margin: 0;
  padding: 0;
`;

export const BoxContent = styled.div`
  height: 120px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
`;

export const BoxText = styled(Typography.Text)<BoxProps>`
  color: ${props =>
    !props.active ? props => props.theme.corPrimaria : 'white'};
  font-size: 30px;
  text-align: left;
  font-weight: bold;
`;

export const BoxTextCurrency = styled(Typography.Text)<BoxProps>`
  color: ${props =>
    !props.active ? props => props.theme.corPrimaria : 'white'};
  font-size: 14px;
  text-align: left;
  margin-right: 4px;
`;

export const Wrapper = styled.div`
  flex: 1;
  margin: 0 4px;
`;

export const Header = styled.div`
  min-height: 70px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: white;
  padding: 10px;
`;

export const ContainerResult = styled.div`
  flex: 1;
`;

export const Message = styled(Typography.Text)`
  font-size: 18px;
  color: green;
  text-align: center;
  text-transform: uppercase;
`;

export const Valor = styled.h1`
  font-size: 40px;
  color: ${props => props.theme.corPrimaria};
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
`;

export const VencimentoLabel = styled.h1`
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 10px;
`;

export const Vencimento = styled.div`
  font-size: 24px;
  color: ${props => props.theme.corPrimaria};
  text-align: center;
  text-transform: uppercase;
  margin-top: 10px;
`;

export const LinhaDigitalvelLabel = styled.div`
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 40px;
`;

export const Warning = styled.h1`
  font-size: 16px;
  color: ${props => props.theme.corTextoPrimaria};
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
`;

export const LinhaDigitalvel = styled.div`
  font-size: 18px;
  color: ${props => props.theme.corPrimaria};
  text-align: center;
  text-transform: uppercase;
  margin-right: 10px;
`;

export const LinhaDigitalvelContainer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  margin-bottom: 20px;
  display: flex;
`;
