import React from 'react';

const Dashboard: React.FC = () => {
  return (
    <>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
      <h1>wefwef</h1>
    </>
  );
};

export default Dashboard;
