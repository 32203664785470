import { Buffer } from 'buffer';
import md5 from 'md5';
import { getApp } from './apps';

let sd = window.location.href.replace('http://', '');
sd = sd.replace('https://', '').split('/')[1] || 'apcapsp';

export const subdomain = sd;

let appId: string | undefined;

try {
  appId = getApp(subdomain)?.appId;

  if (!appId) throw new Error('Aplicação não disponível.');
} catch (error) {
  window.location.replace(
    window.location.href.replace(subdomain, 'apcapsp') + '/404',
  );
  console.log(error);
}

const AppVersion = '4.0.0';
const card = '0030000936';

// TODO: criar função para gerar cards
const getCard = (): string => {
  return card;
};

const getAccessToken = (cpf: string): string => {
  const cpfAppId = md5(cpf.replace(/[^0-9]+/g, '') + appId).toString();
  const cardBase64 = Buffer.from(getCard()).toString('base64');
  const accessToken = Buffer.from(`${cpfAppId}:${cardBase64}`).toString(
    'base64',
  );
  return accessToken;
};

export { appId, AppVersion, getAccessToken };
