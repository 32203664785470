import styled from 'styled-components';

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    line-height: 24px;
    align-items: center;
    justify-content: center;
    span {
      color: #f4ede8;
    }

    a {
      text-decoration: none;
      color: white;
      text-align: center;
      &:hover {
        opacity: 0.8;
      }
    }
  }
`;
