import { Tabs } from 'antd';
import { ProdutoConfigType } from 'model/config/ProdutoConfig';
import { ReservaType } from 'model/Reserva';
import React from 'react';
import TicketInfo from '../TicketInfo';

const { TabPane } = Tabs;

interface TicketChanceProps {
  option: ReservaType;
  product: ProdutoConfigType;
}

const TicketChance: React.FC<TicketChanceProps> = ({ option, product }) => {
  return (
    <div>
      <Tabs type="card">
        {product &&
          option &&
          option.certificados.map((certificado, index) => {
            return (
              <TabPane
                tab={index + 1 + 'ª Chance'}
                key={certificado.numero + index}
                style={{ textAlign: 'center' }}
              >
                <TicketInfo
                  certificado={certificado}
                  product={product}
                  dezenas={option.dezenas}
                />
              </TabPane>
            );
          })}
      </Tabs>
    </div>
  );
};

export default TicketChance;
