import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';

import md5 from 'md5';

import { updatePerson } from '../store/modules/user/actions';
import { subdomain } from '../services/helper';
import api, { responseInterceptor } from '../services/api';
import { notification } from 'antd';

interface User {
  idPessoa?: number;
  Nome?: string;
  urlImageProfile?: string;
  cpf?: string;
  anonymous?: boolean;
  ativo?: boolean;
}

interface AuthState {
  authorization?: string;
  user: User;
}

interface SignCredentials {
  cpf: string;
  senha: string;
}

interface Person {
  idPessoa?: number;
  cpf: string;
  ativo: boolean;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignCredentials): Promise<void>;
  signOut(): void;
  authorization?: string;
  loading: boolean;
  setAnonymous(credentials: SignCredentials): Promise<void>;
  setLogged(
    idPessoa: number,
    authorization: string,
    nome: string,
    cpf: string,
  ): Promise<void>;
  person?: Person;
  setPerson(person?: Person): void;
  setImageProfile(url: string): void;
}

const appName = 'ApcapWeb@' + subdomain;
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>({} as AuthState);
  const [loading, setLoading] = useState(true);
  const [person, setPerson] = useState<Person>();
  const dispatch = useDispatch();

  const signOut = useCallback(async () => {
    if (data && data.user && !data.user.anonymous) {
      setPerson(undefined);
      await api.get('/auth/sair', {
        headers: { authorization: data.authorization },
      });
    }

    localStorage.removeItem(`${appName}:authorization`);
    localStorage.removeItem(`${appName}:user`);
    localStorage.removeItem(`${appName}:config`);

    setData({} as AuthState);
    setPerson(undefined);
  }, [data]);

  useEffect(() => {
    dispatch(updatePerson(person));
  }, [person, dispatch]);

  useEffect(() => {
    async function loadStoragedData(): Promise<void> {
      const authorization = localStorage.getItem(`${appName}:authorization`);
      const user = localStorage.getItem(`${appName}:user`);

      if (authorization && user) {
        setData(oldData => {
          if (!oldData.authorization && !oldData.user) {
            return {
              authorization,
              user: JSON.parse(user || ''),
            } as AuthState;
          }
          return oldData;
        });
        setPerson(JSON.parse(user || ''));
      }

      setLoading(false);
    }
    loadStoragedData();
  }, []);

  useEffect(() => {
    // load interceptor response
    responseInterceptor(signOut, notification);
  }, [signOut]);

  const signIn = useCallback(async ({ cpf, senha }) => {
    setLoading(true);
    api
      .post('/auth', { cpf, senha: md5(senha) })
      .then(async response => {
        const { Authorization, User } = response.data;
        User.cpf = cpf;
        User.anonymous = false;
        localStorage.setItem(`${appName}:authorization`, Authorization);
        localStorage.setItem(`${appName}:user`, JSON.stringify(User));
        setData({ authorization: Authorization, user: User });
        setPerson(User);
      })
      .catch(error => {
        notification.error({
          message: 'Atenção 😬',
          description: error.response.data.mensagem,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const setLogged = useCallback(async (idPessoa, authorization, nome, cpf) => {
    const user: User = {};
    user.anonymous = false;
    user.idPessoa = idPessoa;
    user.Nome = nome;
    user.cpf = cpf;
    localStorage.setItem(`${appName}:authorization`, authorization);
    localStorage.setItem(`${appName}:user`, JSON.stringify(user));
    setData({ authorization, user });
  }, []);

  const setAnonymous = useCallback(async ({ cpf }) => {
    const user: User = {};
    user.cpf = cpf;
    user.anonymous = true;
    localStorage.setItem(`${appName}:user`, JSON.stringify(user));
    setData({ user });
  }, []);

  const setImageProfile = async (url: string): Promise<void> => {
    const newUser = { ...data.user };
    newUser.urlImageProfile = url;
    setData({ user: newUser, authorization: data.authorization });
    localStorage.setItem(`${appName}:user`, JSON.stringify(newUser));
  };

  return (
    <AuthContext.Provider
      value={{
        user: data.user as User,
        authorization: data.authorization,
        loading,
        signIn,
        signOut,
        setAnonymous,
        setLogged,
        person,
        setPerson,
        setImageProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
