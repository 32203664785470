import React from 'react';
import { Menu, Modal } from 'antd';

import {
  FaUser,
  FaBarcode,
  FaWallet,
  FaCreditCard,
  FaQuestionCircle,
  FaSignOutAlt,
  FaHome,
  FaTicketAlt,
  FaUserFriends,
} from 'react-icons/fa';
import { useAuth } from 'hooks/auth';
import { useHistory } from 'react-router-dom';
import { useConfig } from 'hooks/config';
import { useTheme } from 'styled-components';
import { Profile } from 'components/Profile';

interface SideMenuProps {
  onClose: () => void;
}
const { confirm } = Modal;

export const SideMenu: React.FC<SideMenuProps> = ({ onClose }) => {
  const history = useHistory();
  const theme = useTheme();

  const { signOut } = useAuth();
  const { products } = useConfig();

  const getIcon = (iconName: string) => {
    let icon;
    switch (iconName) {
      case 'home':
        icon = <FaHome size={20} />;
        break;
      case 'account':
        icon = <FaUser size={20} />;
        break;
      case 'ticket':
        icon = <FaTicketAlt size={20} />;
        break;
      case 'wallet':
        icon = <FaWallet size={20} />;
        break;
      case 'barcode':
        icon = <FaBarcode size={20} />;
        break;
      case 'credit-card-multiple':
        icon = <FaCreditCard size={20} />;
        break;
      case 'friends':
        icon = <FaUserFriends size={20} />;
        break;
      case 'help-circle':
        icon = <FaQuestionCircle size={20} />;
        break;
      case 'exit-to-app':
        icon = <FaSignOutAlt size={20} />;
        break;
      default:
        break;
    }
    return icon;
  };

  function showConfirm() {
    confirm({
      icon: null,
      title: (
        <span role="img" aria-label="emoji logoff" style={{ fontSize: 24 }}>
          🥺 Confirme
        </span>
      ),
      content: 'Que pena, fica mais um pouco, toma um café.',
      okText: 'Sair',
      cancelText: 'Ficar',
      onOk() {
        signOut();
      },
      onCancel() {},
    });
  }

  const items = [
    {
      icon: 'home',
      title: 'Home',
      onClick: () => history.push('/'),
    },
    {
      icon: 'account',
      title: 'Meus Dados e Senha',
      onClick: () => history.push('userdata'),
    },
    {
      icon: 'ticket',
      title:
        'Meus ' +
        ((products &&
          products[0] &&
          products[0].config?.glossario?.certificados) ||
          'Cupons'),
      onClick: () => history.push('/tickets-products'),
    },
    {
      icon: 'wallet',
      title: 'Extratos',
      onClick: () => history.push('balance'),
    },
    {
      icon: 'barcode',
      title: 'Carteira Apcap',
      onClick: () => history.push('list-bankslip'),
    },
    {
      icon: 'credit-card-multiple',
      title: 'Meus Cartões',
      onClick: () => history.push('payment-methods'),
    },
    {
      icon: 'friends',
      title: 'Meus Amigos',
      onClick: () => history.push('friends'),
    },
    {
      icon: 'help-circle',
      title: 'Ajuda e informações',
      onClick: () => history.push('help'),
    },
    {
      icon: 'exit-to-app',
      title: 'Sair',
      onClick: () => {
        showConfirm();
      },
    },
  ];

  const handleClick = (onClick: () => void) => {
    onClick();
    onClose();
  };

  return (
    <>
      <Profile />
      <Menu
        mode="inline"
        defaultSelectedKeys={['1']}
        style={{ padding: 0, margin: 0 }}
      >
        {items.map(i => (
          <Menu.Item
            key={i.title}
            style={{
              alignItems: 'center',
              display: 'flex',
              color: theme.corPrimaria,
            }}
            icon={getIcon(i.icon)}
            onClick={() => handleClick(i.onClick)}
          >
            &nbsp;{i.title}
          </Menu.Item>
        ))}
      </Menu>
    </>
  );
};
