import { Col } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;

  && .ant-select-selector,
  .ant-select-single {
    height: 50px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const ItemOption = styled.div`
  span {
    margin-right: 6px;
  }
`;

export const Dezena = styled.div`
  padding: 10;
  border-radius: 40px;
  width: 80px;
  height: 80px;
  background-color: white;
  justify-content: center;
  align-items: flex-end;
  display: flex;

  @media screen and (max-width: 360px) {
    border-radius: 10vw;
    width: 20vw;
    height: 20vw;
    padding: 0;
  }
`;

export const InputDezena = styled(MaskedInput)`
  font-size: 44px;
  font-weight: bold;

  @media screen and (max-width: 360px) {
    font-size: 10vw;
  }
`;

export const COL = styled(Col)`
  justify-content: center;
  display: flex;
  margin: 20px 0;
`;
