import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px 20px 0;
  width: 90%;
  display: flex;
`;

export const GroupContainer = styled.div`
  justify-content: center;
  align-items: center;
  flex: 0.5;
  display: flex;
  flex-direction: column;
`;

export const DezenasSorteadas = styled.div`
  border-radius: 30px;
  width: 60px;
  height: 60px;
  border: solid 3px #4cbceb;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const Ganhadores = styled.div`
  border-radius: 30px;
  width: 60px;
  height: 60px;
  border: solid 3px #1771bd;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const DezenasText = styled.span`
  color: #4cbceb;
  font-size: 30px;
`;

export const DezenasTitle = styled.span`
  color: #4cbceb;
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
`;

export const GanhadoresText = styled.span`
  color: #1771bd;
  font-size: 30px;
`;

export const GanhadoresTitle = styled.span`
  color: #1771bd;
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
`;
