import { createGlobalStyle } from 'styled-components';
import 'react-credit-cards/es/styles-compiled.css';
export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-size: clamp(12px, 2vw, 14px);
    viewport-fit: cover;
  }

  .anticon svg {
    font-size: inherit !important;
  }


  ul,li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  body {
    background:#f2f2f2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 320px;
  }

  body, input, button {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 16px;
  }

  .input-password > input {
    font-size: 40px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  button.close {
    position: absolute;
    top: 15px;
    right: 10px;
    background: white;
    border: none;
  }

  .color-theme {
    color: ${props => props.theme.corTextoPrimaria};
  }

  .background-theme {
    background-color: ${props => props.theme.corPrimaria};
  }

  .color-theme:hover {
    color: ${props => props.theme.corTextoPrimaria};
    opacity: 0.8;
  }

  .background-theme:hover {
    background-color: ${props => props.theme.corPrimaria};
    opacity: 0.8;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .xs-font {
    font-size: clamp(10px, 1.5vw, 12px);
  }

  .md-font {
    font-size: clamp(12px, 2vw, 14px);
  }

  .lg-font {
    font-size: clamp(15px, 4vw, 18px);
  }

  h1 {
    font-size: clamp(32px, 8vw, 32px);
  }
  h2 {
    font-size: clamp(24px, 5vw, 24px);
  }
  h3 {
    font-size: clamp(20px, 3vw, 20px);
  }

  .ant-modal-content {
    min-width: 300px !important;
  }

  .no-wrap-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .no-show-button &&
   div.ant-input-number-handler-wrap {
    display: none;
  }
  .ant-page-header {
    padding: 16px 0px;
  }
`;
