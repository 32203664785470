import styled, { css } from 'styled-components';

interface NumberContentProps {
  last: boolean;
  first: boolean;
}

export const Container = styled.div`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  margin: 0 5px;
  display: flex;
`;

export const Space = styled.div`
  margin: 10px;
`;
export const NumberContent = styled.div<NumberContentProps>`
  background-color: ${props => props.theme.corPrimaria};
  padding: 10px 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${props =>
    props.first &&
    css`
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      margin-right: 2px;
    `}

  ${props =>
    props.last &&
    css`
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    `}

  ${props =>
    !props.last &&
    !props.first &&
    css`
      margin-right: 2px;
    `}
`;

export const Number = styled.span`
  color: #ffffff;
  font-size: 30px;
  text-align: center;
`;

export const Label = styled.span`
  color: ${props => props.theme.corPrimaria};
  font-size: 22px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 6px;
  margin-left: 6px;
`;
