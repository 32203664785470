import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal, Select } from 'antd';
import { BsCreditCard } from 'react-icons/bs';
import { useAuth } from 'hooks/auth';
import api from 'services/api';
import { CardAvailableType, CardType } from 'model/card/Card';
import PaymentIcon from 'react-payment-icons';
import { Container, ItemOption, NewCard, Saldo, AddBalance } from './styles';
import { ProdutoConfigType } from 'model/config/ProdutoConfig';
import { useTheme } from 'styled-components';
import { FaCreditCard, FaMoneyBill } from 'react-icons/fa';
import PaymentMethod from 'pages/PaymentMethod';
import { toCurrency } from 'utils/StringHelper';
import { useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;
const Label = ({
  icon,
  text,
  color,
}: {
  icon: JSX.Element;
  text: string;
  color?: string;
}) => {
  return (
    <span style={{ color }}>
      {icon} <span>&nbsp;{text}</span>
    </span>
  );
};

interface PaymentMethodsProps {
  product: ProdutoConfigType;
  setPayment: (payment: CardType | undefined) => void;
  setUseSaldo: (useSaldo: boolean) => void;
}

const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  product,
  setPayment,
  setUseSaldo,
}) => {
  const { authorization } = useAuth();
  const [cardAvailable, setCardAvailable] = useState<CardAvailableType>();
  const [modalNewCardVisible, setModalNewCardVisible] = useState(false);
  const theme = useTheme();
  const history = useHistory();

  const [selected, setSelected] = useState<number>();
  const [loading, setLoading] = useState(false);

  const loadCards = useCallback(
    async (id?: number): Promise<void> => {
      setLoading(true);
      const response = await api.get('/formasPagamento/disponiveis', {
        headers: { authorization },
      });

      if (response && response.data) {
        setCardAvailable(response.data);
        if (id) {
          setPayment(
            response.data.cartao_credito_debito.lista.find(
              (pay: any) => pay.idFormaDePagamento === id,
            ),
          );
        }
      }
      setLoading(false);
    },
    [authorization, setPayment],
  );

  useEffect(() => {
    loadCards();
  }, [loadCards]);

  const handleChange = useCallback(
    (value: number) => {
      if (value === 0) {
        setModalNewCardVisible(true);
        setUseSaldo(false);
      } else if (value === -1) {
        setUseSaldo(true);
        setPayment(undefined);
      } else {
        setUseSaldo(false);
        setPayment(
          cardAvailable?.cartao_credito_debito.lista.find(
            pay => pay.idFormaDePagamento === value,
          ),
        );
      }
      setSelected(value);
    },
    [cardAvailable, setPayment, setUseSaldo],
  );

  const handleSaveNewCard = useCallback(
    async (id: number) => {
      setModalNewCardVisible(false);
      await loadCards(id);
      setSelected(id);
    },
    [loadCards],
  );

  const handleCancelNewCard = () => {
    setModalNewCardVisible(false);
    setPayment(undefined);
    setSelected(undefined);
  };

  return (
    <>
      <Container>
        <Form layout="vertical" style={{ width: '90%' }}>
          <Form.Item
            label={
              <Label
                icon={<BsCreditCard />}
                text="Forma de Pagamento"
                color={product.config?.web?.corPrimaria || theme.corPrimaria}
              />
            }
          >
            <Select
              style={{ width: '100%' }}
              placeholder="Selecione a forma de pagamento"
              onChange={handleChange}
              optionLabelProp="label"
              value={selected}
              loading={loading}
            >
              {cardAvailable &&
                cardAvailable.cartao_credito_debito.lista.map(card => (
                  <Option
                    key={card.idFormaDePagamento}
                    value={card.idFormaDePagamento}
                    label={
                      <>
                        <span role="img" aria-label="payment icon">
                          <PaymentIcon
                            id={
                              String(card.bandeiraCartao).toLowerCase() !==
                              'hipercard'
                                ? String(card.bandeiraCartao).toLowerCase()
                                : 'generic'
                            }
                            style={{ marginRight: 10, width: 50 }}
                            className="payment-icon"
                          />
                        </span>
                        {card.numeroCartao}&nbsp;
                        <span>
                          | Cartão de{' '}
                          {card.tipoCartao === 1 ? ' Débito' : ' Crédito'}
                        </span>
                      </>
                    }
                  >
                    <ItemOption>
                      <span role="img" aria-label="payment icon">
                        <PaymentIcon
                          id={
                            String(card.bandeiraCartao).toLowerCase() !==
                            'hipercard'
                              ? String(card.bandeiraCartao).toLowerCase()
                              : 'generic'
                          }
                          style={{ marginRight: 10, width: 50 }}
                          className="payment-icon"
                        />
                      </span>
                      {card.numeroCartao}&nbsp;
                      <span>
                        | Cartão de{' '}
                        {card.tipoCartao === 1 ? ' Débito' : ' Crédito'}
                      </span>
                    </ItemOption>
                  </Option>
                ))}
              <Option
                value={0}
                label={
                  <NewCard style={{ marginTop: 34 }}>
                    <FaCreditCard size={46} color="green" />
                    <div>Adicionar Novo Cartão</div>
                  </NewCard>
                }
              >
                <NewCard>
                  <FaCreditCard size={46} color="green" />
                  <div>Adicionar Novo Cartão</div>
                </NewCard>
              </Option>
              {cardAvailable && cardAvailable.saldo_plataforma.habilitado && (
                <Option
                  key="saldo"
                  value={-1}
                  label={
                    <Saldo style={{ marginTop: 34 }}>
                      <FaMoneyBill size={46} color={theme.corPrimaria} />
                      <div>
                        Utilizar do meu Saldo{' '}
                        {toCurrency(cardAvailable.saldo_plataforma.saldo)}
                      </div>
                    </Saldo>
                  }
                >
                  <Saldo>
                    <FaMoneyBill size={46} color={theme.corPrimaria} />
                    <AddBalance>
                      Utilizar do meu Saldo{' '}
                      {toCurrency(cardAvailable.saldo_plataforma.saldo)}
                      <Button
                        type="primary"
                        size="small"
                        icon={<PlusOutlined />}
                        shape="round"
                        onClick={() => history.push('/add-balance')}
                      >
                        Adicionar Saldo
                      </Button>
                    </AddBalance>
                  </Saldo>
                </Option>
              )}
            </Select>
          </Form.Item>
        </Form>
      </Container>
      <Modal
        title={
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <FaCreditCard color={theme.corPrimaria} size={24} />
            &nbsp;Adicionar Cartão
          </p>
        }
        visible={modalNewCardVisible}
        okButtonProps={{ hidden: true }}
        onCancel={handleCancelNewCard}
      >
        <PaymentMethod isModal={true} onSave={handleSaveNewCard} />
      </Modal>
    </>
  );
};

export default PaymentMethods;
