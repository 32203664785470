import { NotificationApi } from 'antd/lib/notification';
import axios from 'axios';
import { store } from '../store';
import { appId, getAccessToken, AppVersion } from './helper';

export const baseURL = process.env.REACT_APP_BASE_URL;
const device = 'web';
const api = axios.create({
  baseURL,
  headers: {
    appId,
    device,
    AppVersion,
  },
});

// INTERCEPTORS
api.interceptors.request.use(
  async config => {
    let cpf;
    if (config && config.url && config.url.includes('/pessoa?cpf=')) {
      const acpf = config.url.split('=');
      // eslint-disable-next-line prefer-destructuring
      if (acpf.length) cpf = acpf[1];
    } else {
      const { user }: any = store.getState();
      cpf = user && user.person && user.person.cpf;
    }

    const accessToken = cpf ? getAccessToken(cpf.toString()) : null;
    if (accessToken) {
      return { ...config, headers: { ...config.headers, accessToken } };
    }
    return config;
  },
  error => {
    // I cand handle a request with errors here
    return Promise.reject(error);
  },
);

export const responseInterceptor = (
  signOut: () => void,
  notification: NotificationApi,
): any => {
  function newToast(message: string, option?: any) {
    notification.error({ message: 'Atenção 😬', description: message });
  }

  api.interceptors.response.use(
    response => {
      return response;
    },

    error => {
      if (
        error?.response?.config?.url === `/codigoPromocional/resgate` ||
        error?.response?.config?.url === `/certificadoDeContribuicao/compra` ||
        error?.response?.config?.url === `/seguro/comprar` ||
        error?.response?.config?.url.includes('/pessoa?cpf=') ||
        error?.response?.config?.url.includes('/auth/esqueci') ||
        error?.response?.config?.url.includes('/auth/validarCodigo') ||
        error?.response?.config?.url.includes('/auth/redefinir') ||
        (error?.response?.config?.url.includes('/auth') &&
          !error?.response?.config?.url.includes('/auth/esqueci') &&
          !error?.response?.config?.url.includes('/auth/sair')) ||
        (error?.response?.config?.url.includes('/usuario') &&
          !error?.response?.config?.url.includes('/usuario/meusDados') &&
          !error?.response?.config?.url.includes('/usuario/avaliacaoApp') &&
          !error?.response?.config?.url.includes('/usuario/atualizar'))
      ) {
        throw error;
      }
      if (!error.response || !error.response.data) {
        newToast('Algo inesperado aconteceu, tente novamente em instantes.');

        return error;
      }
      switch (error.response.status) {
        case 400:
        case 412:
          newToast(error.response.data.mensagem || 'Requisição inválida');
          return error;
        case 401:
          newToast(error.response.data.mensagem || 'Não Autorizado');
          signOut();
          return error;
        case 404:
          newToast('Recurso não disponível.');
          return error;
        case 403:
          newToast('Sessão Expirada. Faça login novamente.');
          signOut();
          return error;
        case 500:
          newToast('Algo inesperado aconteceu, tente novamente em instantes.');
          return error;
        default:
          newToast(
            error.response.data.mensagem ||
              'Algo inesperado aconteceu, tente novamente em instantes.',
          );
          return error;
      }
    },
  );
};

export default api;
