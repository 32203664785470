import React, { useState, useCallback, useEffect, useRef } from 'react';

import { useAuth } from 'hooks/auth';
import { BoletoType } from 'model/Boleto';
import api from 'services/api';
import { toCurrency } from 'utils/StringHelper';

import {
  Valor,
  VencimentoLabel,
  Vencimento,
  LinhaDigitalvelLabel,
  LinhaDigitalvel,
  LinhaDigitalvelContainer,
  Modal,
} from './styles';
import { Button, Result } from 'antd';
import { FaCheck, FaCopy } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';

interface ResultBankSlipProps {
  boleto: BoletoType;
  showResult: boolean;
  setShowResult: (show: boolean) => void;
}

let timeCopy: number;

const ResultBankSlip: React.FC<ResultBankSlipProps> = ({
  boleto,
  showResult,
  setShowResult,
}) => {
  const [loading, setLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [copyText, setCopyText] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const { authorization } = useAuth();
  const theme = useTheme();
  const history = useHistory();

  useEffect(() => {
    if (timeCopy) clearTimeout(timeCopy);
    if (copyText && textAreaRef.current) {
      textAreaRef.current.select();
      document.execCommand('copy');
      // This is just personal preference.
      // I prefer to not show the whole text area selected.
      setCopySuccess('Copiado!');
      timeCopy = setTimeout(() => {
        setCopySuccess('');
        setCopyText('');
      }, 2000);
    }
    return () => {
      if (timeCopy) clearTimeout(timeCopy);
    };
  }, [copyText]);

  const getPdf = useCallback(async () => {
    setLoading(true);
    const response = await api.get(`/boleto/${boleto?.id}/download`, {
      headers: { authorization },
      responseType: 'arraybuffer',
    });
    setLoading(false);
    if (response?.data) {
      var file = new Blob([response?.data], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL, 'boleto');
    }
  }, [boleto, authorization]);

  function copyToClipboard(value: string) {
    const ld = String(value).replace(/[^\d]/g, '');
    setCopyText(ld);
  }

  const closeModal = () => {
    setShowResult(false);
    history.push('/');
  };

  return (
    <>
      <Modal
        visible={showResult}
        onCancel={closeModal}
        title="Parabéns"
        cancelText="Fechar"
        okButtonProps={{ hidden: true }}
      >
        {boleto && (
          <Result
            status="success"
            title="Boleto gerado com sucesso!"
            subTitle="Este boleto estará disponível para pagamento em alguns minutos."
            extra={[
              <div key="boletoResult">
                <Valor>{toCurrency(boleto?.valor)}</Valor>
                <VencimentoLabel>Data de Vencimento</VencimentoLabel>
                <Vencimento>{boleto.dataVencimento}</Vencimento>
                <LinhaDigitalvelLabel>Linha Digitável</LinhaDigitalvelLabel>
                <LinhaDigitalvelContainer
                  style={{ marginLeft: 30, marginRight: 30 }}
                >
                  <LinhaDigitalvel>{boleto.linhaDigitavel}</LinhaDigitalvel>
                  <div>
                    {document.queryCommandSupported('copy') &&
                      boleto.linhaDigitavel &&
                      !copySuccess && (
                        <FaCopy
                          size={20}
                          color={theme.corPrimaria}
                          title="Copiar linha digitável"
                          onClick={() => copyToClipboard(boleto.linhaDigitavel)}
                        />
                      )}
                    {copyText ===
                      String(boleto.linhaDigitavel).replace(/[^\d]/g, '') &&
                      copySuccess && <FaCheck size={20} color="green" />}
                  </div>
                </LinhaDigitalvelContainer>
              </div>,
              <Button
                loading={loading}
                type="primary"
                key="btnPdf"
                onClick={getPdf}
              >
                Ver Boleto PDF
              </Button>,
            ]}
          />
        )}
      </Modal>
      <textarea
        ref={textAreaRef}
        value={copyText}
        style={{ display: 'block', opacity: 0, width: 0, height: 0 }}
      />
    </>
  );
};

export default ResultBankSlip;
