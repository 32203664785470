import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px 20px 0;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  && .ant-card-head-title {
    font-size: 24px;
    color: ${props => props.theme.corPrimaria};
  }
`;

export const Title = styled.h3`
  color: ${props => props.theme.corPrimaria};
`;
export const Value = styled.h2`
  color: #00000070;
`;
