/* eslint-disable no-param-reassign */
import { Reducer } from 'react';

import produce from 'immer';
import { AnyAction } from 'redux';

const INITIAL_STATE = {
  person: {},
  payments: [],
  lastPaymentUsed: undefined,
};

export interface UserStateType {
  person: any;
  payments: any[];
  lastPaymentUsed: 'cartao' | 'boleto' | 'saldo' | undefined;
}

interface UserActionType extends AnyAction {
  payload: UserStateType;
}

const user: Reducer<UserStateType, UserActionType> = (
  state = INITIAL_STATE,
  { type, payload },
) => {
  return produce(state, (draftState: UserStateType) => {
    switch (type) {
      case '@user/UPDATE_LAST_PAYMENT_USED': {
        draftState.lastPaymentUsed = payload.lastPaymentUsed;
        break;
      }
      case '@user/UPDATE_PAYMENTS': {
        draftState.payments = payload.payments;
        break;
      }
      case '@user/UPDATE_PERSON': {
        draftState.person = payload.person;
        break;
      }

      default:
    }
  });
};

export default user;
