import React, { useEffect, useState, useCallback } from 'react';

import { debounce } from 'lodash';

import { useAuth } from 'hooks/auth';
import { useConfig } from 'hooks/config';
import { ProdutoConfigType } from 'model/config/ProdutoConfig';
import { CupomType } from 'model/Cupom';
import { CuponsTotalType } from 'model/CuponsTotal';
import { ProdutoType } from 'model/Produto';
import api from 'services/api';

import Search from '../Search';
import {
  Container,
  TicketsInProgress,
  TicketsInProgressText,
  TicketsInProgressTextBold,
} from './styles';
import TicketItem from './TicketItem';
import { Spin } from 'antd';

interface TicketListType {
  product: ProdutoType;
}
let time: number;

const TicketList: React.FC<TicketListType> = ({ product }) => {
  const [tickets, setTickets] = useState<CupomType[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [ticketProcessando, setTicketProcessando] = useState(false);
  const [searchText, setSearchText] = useState<string>();
  const { authorization } = useAuth();
  const [cuponsTotal, setCuponsTotal] = useState<CuponsTotalType>();
  const { getProductConfig } = useConfig();
  const [produtoConfig, setProdutoConfig] = useState<ProdutoConfigType>();

  const loadProdutoConfig = useCallback(() => {
    if (product?.codigo) {
      getProductConfig(product?.codigo).then(response => {
        setProdutoConfig(response);
      });
    }
  }, [product, getProductConfig]);

  useEffect(() => {
    loadProdutoConfig();
  }, [loadProdutoConfig]);

  const getTickets = useCallback(
    async (pageNumber = page, shouldRefresh = false): Promise<void> => {
      if (total > 0 && pageNumber > total) return;
      if (loading) return;

      setLoading(true);
      const response = await api.post(
        `/cupom`,
        {
          produto: product.codigo,
          q: searchText,
          pagina: pageNumber,
          limite: 4,
        },
        {
          headers: { authorization },
        },
      );
      if (response && response.data) {
        const data = (response.data && response.data.values) || [];
        setTotal(response.data.totalPages || 0);

        setPage(pageNumber + 1);

        setTickets(shouldRefresh ? data : [...tickets, ...data]);
      }
      setLoading(false);
    },
    [authorization, searchText, product, page, tickets, total, loading],
  );

  const getCuponsTotal = useCallback(async (): Promise<void> => {
    if (loading) return;
    const response = await api.get('/cupom/total', {
      headers: { authorization },
    });
    if (
      response &&
      response.data.brindesNaoProcessados === 0 &&
      ticketProcessando
    ) {
      setTicketProcessando(false);
      setTotal(0);
      await getTickets(1, true);
    }
    setCuponsTotal(response && response.data);
  }, [authorization, getTickets, loading, ticketProcessando]);

  useEffect(() => {
    getCuponsTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      cuponsTotal?.brindesNaoProcessados &&
      cuponsTotal?.brindesNaoProcessados > 0
    ) {
      setTicketProcessando(true);
      if (time) clearTimeout(time);
      time = setTimeout(() => {
        getCuponsTotal();
      }, 10000);
    } else if (time) clearTimeout(time);
    return () => clearTimeout(time);
  }, [cuponsTotal, getCuponsTotal]);

  const onChangeText = debounce((text: string): void => {
    setTotal(0);
    setSearchText(text);
  }, 700);

  const handleChange = useCallback(text => onChangeText(text), [onChangeText]);

  useEffect(() => {
    getTickets(1, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const renderHeader = useCallback(
    (): JSX.Element => (
      <>
        {/* <Search onChangeText={handleChange} /> */}
        {/* {loading && !refreshing && (
        <ActivityIndicator size="large" color={colors.primaryDark} />
      )} */}
      </>
    ),
    [handleChange],
  );

  return (
    <>
      {!!cuponsTotal?.brindesNaoProcessados && (
        <TicketsInProgress
          animation="bounceInDown"
          delay={1000}
          duration={2000}
        >
          <Spin size="small" />
          <TicketsInProgressText>
            Você tem
            <TicketsInProgressTextBold>
              {` ${cuponsTotal.brindesNaoProcessados} `}
              {cuponsTotal.brindesNaoProcessados === 1
                ? produtoConfig?.config?.glossario?.certificado
                : produtoConfig?.config?.glossario?.certificados}
            </TicketsInProgressTextBold>
            &nbsp;em Processamento
          </TicketsInProgressText>
        </TicketsInProgress>
      )}
      {renderHeader()}
      <Container>
        {product &&
          produtoConfig &&
          tickets &&
          tickets.map((ticket, index) => (
            <TicketItem
              key={ticket.idTituloPromocao}
              ticket={ticket}
              index={index}
              product={product}
              produtoConfig={produtoConfig}
            />
          ))}
        {loading && <Spin size="large" />}
      </Container>
    </>
  );
};

export default TicketList;
