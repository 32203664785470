import styled, { css } from 'styled-components';

interface WidthProps {
  width: number | null;
  vertical?: boolean;
  isGoodDozen?: string;
}

export const Container = styled.div<WidthProps>`
  width: ${props => (props.width ? props.width : window.screen.width)}px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 6px;
  margin-bottom: 20px;
  border-bottom-width: 1px;
  border-bottom-color: ${props => props.theme.corTextoPrimaria};
  display: flex;
`;

export const ContainerTexts = styled.div`
  margin-left: 10px;
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: 24px;
  padding: 6px;
`;
export const Description = styled.span`
  font-size: 14px;
`;

export const DescriptionPDV = styled.span`
  font-size: 13px;
  text-transform: capitalize;
`;

export const Image = styled.img`
  width: ${window.screen.width >= 400 ? 70 : 50}px;
  height: ${window.screen.width >= 400 ? 70 : 50}px;
  border-radius: 35px;
  margin-left: 10px;
`;

export const ImageIconContainter = styled.div`
  margin-right: 4px;
`;

export const ImageIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-left: 6px;
`;

export const DezenaContainer = styled.div<WidthProps>`
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  display: flex;
  ${props =>
    props.vertical &&
    css`
      height: ${props.width && props.width}px;
    `}
`;

export const Dezena = styled.div<WidthProps>`
  width: ${props => props.width && (props.width - 48) / 5}px;
  height: ${props => props.width && (props.width - 96) / 4}px;
  border: solid 1px ${props => props.theme.corTextoSecundaria};
  justify-content: center;
  align-items: center;
  margin: 4px;
  background-image: url(${props => props.isGoodDozen && props.isGoodDozen});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
`;

export const DezenaText = styled.span`
  color: ${props => props.theme.corPrimaria};
  text-align: center;
  font-weight: bold;
  font-size: 20px;
`;

export const FeefbackContainer = styled.div`
  max-height: ${window.screen.height * 0.15}px;
  margin-bottom: 10px;
`;
export const Feedback = styled.span`
  font-size: 14px;
  /* margin-bottom: 10px; */
`;

export const Subtitle = styled.span`
  margin-top: 10px;
  padding: 10px;
  color: ${props => props.theme.corPrimaria};
  font-size: 18px;
`;
