import styled, { css } from 'styled-components';

interface WidthProps {
  width: number | null;
  selected?: boolean;
  vertical?: boolean;
  isGoodDozen?: string | null;
}
interface SelectedProps {
  selected?: boolean;
}
export const Wrapper = styled.div`
  /* justify-content: center;
  align-items: center; */
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div<WidthProps>`
  /* margin: 0 4px; */
  width: ${props => props.width && props.width}px;
  display: flex;
  flex-direction: column;
`;
export const DezenaContainer = styled.div<WidthProps>`
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  display: flex;
  ${props =>
    props.vertical &&
    css`
      height: ${props.width && props.width}px;
    `}
`;

export const Dezena = styled.div<WidthProps>`
  width: ${props => props.width && (props.width - 48) / 5}px;
  height: ${props => props.width && (props.width - 96) / 4}px;
  border: solid 1px ${props => props.theme.corTextoSecundaria};
  justify-content: center;
  align-items: center;
  margin: 4px;
  background-color: ${props => (props.selected ? '#0668B4' : 'transparent')};
  background-image: url(${props => props.isGoodDozen && props.isGoodDozen});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
`;

export const DezenaText = styled.span<SelectedProps>`
  /* padding: 10px; */
  /* margin: 2px 4px; */
  color: ${props => (props.selected ? '#ffffff' : props.theme.corPrimaria)};
  text-align: center;
  font-weight: bold;
  font-size: 20px;
`;

export const Header = styled.div`
  background-color: #0d095d;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-left: 5px;
  width: 100%;
  display: flex;
`;

export const HeaderText = styled.span`
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  opacity: 0.8;
  line-height: 1;
  width: 100%;
`;

export const Footer = styled.div`
  background-color: #0d095d;
  flex-direction: row;
  height: 40px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  margin-bottom: 14px;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  width: 100%;
  display: flex;
`;

export const FooterText = styled.span`
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
`;
