import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react';

// import { differenceInSeconds } from 'date-fns';

import { ConfigType } from '../model/config/Config';
import { ProdutoConfigType } from '../model/config/ProdutoConfig';
import { subdomain } from '../services/helper';
import api from '../services/api';

interface ConfigContextData {
  config: ConfigType;
  refresh(): void;
  loading: boolean;
  getProductConfig(productCode: string): Promise<ProdutoConfigType | undefined>;
  products: ProdutoConfigType[];
  product: ProdutoConfigType | undefined;
  setMainProduct(code: string): void;
}

const appName = 'ApcapWeb@' + subdomain;

const ConfigContext = createContext<ConfigContextData>({} as ConfigContextData);

const ConfigProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<ConfigType>({} as ConfigType);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<ProdutoConfigType[]>([]);
  const [product, setProduct] = useState<ProdutoConfigType>();

  const loadConfig = useCallback(async () => {
    const response = await api.get('/aplicacao/configuracoes/app');
    const { appConfig } = response.data || {};
    const { produtos } = response.data || [];

    localStorage.setItem(
      `${appName}:config`,
      JSON.stringify({ appConfig, produtos }),
    );
    setData({ appConfig, produtos });
    setProducts(produtos);
  }, []);

  useEffect(() => {
    async function loadStoragedData(): Promise<void> {
      const localData = localStorage.getItem(`${appName}:config`);
      if (localData) {
        setData({
          appConfig: JSON.parse(localData).appConfig,
          produtos: JSON.parse(localData).produtos,
        });
        setProducts(JSON.parse(localData).produtos);
      } else {
        await loadConfig();
      }

      setLoading(false);
    }
    loadStoragedData();
  }, [loadConfig]);

  const refresh = useCallback(() => {
    loadConfig();
  }, [loadConfig]);

  const getProductConfig = useCallback(
    async (productCode: string) => {
      return products.find(p => p.codigo === productCode);
    },
    [products],
  );

  const setMainProduct = useCallback(
    (code: string) => {
      if (products) setProduct(products.find(p => p.codigo === code));
    },
    [products],
  );

  useEffect(() => {
    setMainProduct(subdomain);
  }, [setMainProduct]);

  return (
    <ConfigContext.Provider
      value={{
        config: data,
        refresh,
        loading,
        getProductConfig,
        products,
        product,
        setMainProduct,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

function useConfig(): ConfigContextData {
  const context = useContext(ConfigContext);

  if (!context) {
    throw new Error('useConfig must be used within an ConfigProvider');
  }
  return context;
}

export { ConfigProvider, useConfig };
