import styled from 'styled-components';

export const Container = styled.div``;

export const TituloWrapper = styled.div`
  background-color: ${props => props.theme.corPrimaria};
  text-align: center;
  border-radius: 10px;
`;

export const Label = styled.h3`
  text-align: center;
  color: white;
  margin: 0;
`;

export const Titulo = styled.h1`
  text-align: center;
  color: white;
  margin: 0;
  font-weight: bold;
`;

export const DezenasWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 20px;
  flex-direction: row;
  flex-wrap: wrap;
`;

interface DezenaProps {
  selected: boolean;
  widthWrapper: number;
}

export const Dezena = styled.h2<DezenaProps>`
  width: ${props => props.widthWrapper / 5}px;
  height: ${props => props.widthWrapper / 5}px;
  border-radius: ${props => props.widthWrapper / 10}px;
  background-color: ${props =>
    props.selected ? props.theme.corPrimaria : '#eee'};
  font-weight: bold;
  margin: 3px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  color: ${props => (props.selected ? 'white' : 'black')};
`;
