import { useState } from 'react';

import api from 'services/api';
import { useAuth } from 'hooks/auth';

export default function usePaymentMethod() {
  const [loading, setLoading] = useState(false);
  const { authorization } = useAuth();

  async function saveCard(value: any) {
    try {
      setLoading(true);

      const newCard = {
        tipoCartao: value.tipoCartao,
        bandeiraCartao: value.issuer,
        numeroCartao: value.number,
        validadeCartao: value.expiry,
        nomeCompletoCartao: value.name,
        bandeira: value.issuer,
      };

      const { data } = (await api.post(`/formasPagamento`, newCard, {
        headers: { authorization },
      })) || {
        data: null,
      };

      return data && data.idFormaDePagamento;
    } finally {
      setLoading(false);
    }
  }

  return { saveCard, loading };
}
