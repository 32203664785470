import React, { useCallback, useEffect, useState } from 'react';
import { Collapse, Input, PageHeader, Skeleton } from 'antd';
import { FiHelpCircle } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { FaqType } from 'model/Faq';
import api from 'services/api';
import { useConfig } from 'hooks/config';
import { Select } from 'antd';
import { ProdutoConfigType } from 'model/config/ProdutoConfig';

const { Option } = Select;
const { Panel } = Collapse;
const { Search } = Input;

const Help: React.FC = () => {
  const theme = useTheme();
  const { products } = useConfig();

  const [faqs, setFaqs] = useState<FaqType[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [product, setProduct] = useState<ProdutoConfigType>();

  const loadFaqs = useCallback(async (): Promise<void> => {
    setLoading(true);
    if (product) {
      const response = await api.post('faq/listar', {
        produto: product.codigo,
      });
      if (response && response.data) {
        setFaqs(response.data);
      }
    }
    setLoading(false);
  }, [product]);

  useEffect(() => {
    if (products && products.length === 1) {
      setProduct(products[0]);
    }
  }, [products]);

  useEffect(() => {
    if (product) loadFaqs();
  }, [product, loadFaqs]);

  const handleChangeText = (value: string) => {
    setSearch(value);
  };

  const handleSelectProduct = (value: string) => {
    setProduct(products.find(p => p.codigo === value));
  };

  return (
    <>
      <PageHeader
        backIcon={false}
        title={
          <h3 style={{ color: theme.corPrimaria }}>
            <FiHelpCircle size={20} /> Ajuda e informações
          </h3>
        }
      />

      {products.length > 1 && (
        <Select
          onChange={handleSelectProduct}
          placeholder="Selecione o Produto"
          size="large"
          style={{ marginBottom: 20 }}
        >
          {products.map(p => (
            <Option value={p.codigo}>{p.descricao}</Option>
          ))}
        </Select>
      )}

      <Search
        placeholder="Digite aqui sua pesquisa"
        onSearch={handleChangeText}
        enterButton
        style={{ marginBottom: 20 }}
        size="large"
      />
      <Skeleton loading={loading} active />
      <Collapse accordion>
        {faqs &&
          !loading &&
          faqs
            .filter(
              faq =>
                faq.pergunta.toLowerCase().includes(search.toLowerCase()) ||
                faq.resposta.toLowerCase().includes(search.toLowerCase()),
            )
            .map(faq => (
              <Panel header={faq.pergunta} key={faq.pergunta}>
                <p>{faq.resposta}</p>
              </Panel>
            ))}
      </Collapse>
    </>
  );
};

export default Help;
