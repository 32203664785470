import { Card } from 'antd';
import styled from 'styled-components';

interface ContainerProps {
  color: string;
}
export const Wrapper = styled(Card)<ContainerProps>`
  border-left-color: ${props => props.color};
  border-left-width: 4px;
  padding: 10px 0 0 10px;
  border-top-right-radius: 8px;
  margin: 6px 0px;
  border-bottom-right-radius: 8px;
  /* background-color: white; */
  /* flex: 1; */
  /* display: flex; */
  /* flex-direction: row; */
`;

export const Container = styled.div`
  flex-direction: row;

  background-color: white;
  align-items: center;
  display: flex;
`;

export const ContainerDateTime = styled.div`
  border: solid 2px #ccc;
  padding: 6px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const ContainerDateTimeRow = styled.div<ContainerProps>`
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

export const ContainerDateTimeText = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;

export const ContainerRow = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  display: flex;
`;

export const ContainerTitle = styled.div`
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0 10px;
  display: flex;
`;

export const NumberTicket = styled.span<ContainerProps>`
  color: ${props => props.theme.corPrimaria};
  text-transform: uppercase;
  /* text-align: left; */
  font-size: 24px;
`;
export const LabelNumberTicket = styled.span`
  color: ${props => props.theme.corSecundaria};
  font-size: 12px;
`;

export const Title = styled.span<ContainerProps>`
  color: ${props => props.color};
  text-transform: uppercase;
  text-align: left;
  margin-top: 6px;
`;

export const LabelDateTime = styled.span`
  color: #ccc;
`;

export const ValueDateTime = styled.span`
  color: ${props => props.theme.corPrimaria};
  font-family: 'CocogooseClassic-ExtraBold';
  font-size: 14px;
`;

export const ContainerIcon = styled.div<ContainerProps>`
  background-color: ${props => props.color};
  border-radius: 18px;
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const ButtonText = styled.span`
  color: ${props => props.theme.corSecundaria};
  font-size: 14px;
  border-width: 1px;
  border-radius: 10px;
  border-color: #ccc;
  padding: 4px 30px;
`;

export const StatusContainer = styled.div`
  flex-direction: row;
  background-color: #f8f8f8;
  border-width: 1px;
  border-left-width: 0;
  border-radius: 8px;
  border-color: #ccc;
  padding: 4px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
`;

export const StatusText = styled.span`
  color: ${props => props.theme.corPrimaria};
  font-size: 14px;
  margin-left: 6px;
`;

export const ActionContainer = styled.div<ContainerProps>`
  flex-direction: row;
  background-color: ${props => props.color};
  border-right-width: 0;
  border-radius: 8px;
  border-radius: 8px;
  padding: 4px 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
`;

export const ActionText = styled.span`
  color: #ffffff;
  font-size: 12px;
`;
