import React, { useState, useEffect, useCallback } from 'react';

import { useAuth } from 'hooks/auth';
import { useConfig } from 'hooks/config';
import useBalance from '../Balance/useBalance';
import { SaldoType } from 'model/Saldo';
import { toCurrency } from 'utils/StringHelper';

import {} from './styles';
import {
  Button,
  Card,
  Col,
  Empty,
  List,
  PageHeader,
  Pagination,
  Row,
  Skeleton,
} from 'antd';

import { FaBarcode } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Meta from 'antd/lib/card/Meta';
import Avatar from 'antd/lib/avatar/avatar';

import NoImage from 'assets/perfil-padrao-masculino.png';
import { Data, ButtonContent } from './styles';
import api from 'services/api';
import { BoletoType } from 'model/Boleto';
import LinhaDigitavel from './LinhaDigitavel';

const ListBankSlip: React.FC = () => {
  const { authorization, user } = useAuth();
  const { config } = useConfig();
  const history = useHistory();
  const theme = useTheme();

  const { getSaldo } = useBalance(authorization as string);
  const [loadingSaldo, setLoadingSaldo] = useState(false);
  const [loadingExtrato, setLoadingExtrato] = useState(false);
  const [saldo, setSaldo] = useState<SaldoType>();

  const [bankSlips, setBankSlips] = useState<BoletoType[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const loadSaldo = useCallback(async (): Promise<void> => {
    setLoadingSaldo(true);
    if (config.produtos && config.produtos.length > 0) {
      const produto = config.produtos[0].codigo || 'apcapsp';
      setSaldo(await getSaldo(produto));
    }
    setLoadingSaldo(false);
  }, [config.produtos, getSaldo]);

  const getBankSlips = useCallback(
    async (pageNumber): Promise<void> => {
      if (loadingExtrato) return;

      setLoadingExtrato(true);
      const response = await api.post(
        `/boleto/consulta`,
        {
          pagina: pageNumber,
          limite: 5,
        },
        {
          headers: { authorization },
        },
      );
      if (response && response.data) {
        const data = (response.data && response.data.values) || [];
        setTotal(response.data.totalRows);
        setBankSlips(data);
      }
      setLoadingExtrato(false);
    },
    [authorization, loadingExtrato],
  );

  useEffect(() => {
    loadSaldo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBankSlips(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const renderSituacao = (situacao: string) => {
    switch (situacao) {
      case 'vencido':
        return <b style={{ color: 'red' }}>Vencido</b>;
      case 'pago':
        return <b style={{ color: 'green' }}>Pago</b>;
      case 'pendente':
        return <b style={{ color: 'yellow' }}>Pendente</b>;
      default:
        break;
    }
  };

  return (
    <>
      <PageHeader
        backIcon={false}
        title={
          <>
            <h2 style={{ color: theme.corPrimaria }}>
              <FaBarcode size={20} /> Carteira Apcap
            </h2>
          </>
        }
      />

      <Row gutter={[0, 16]}>
        <Col sm={{ order: 2, span: 10, offset: 4 }} xs={{ order: 1, span: 24 }}>
          <div style={{ textAlign: 'right' }}>
            <Card>
              <Skeleton loading={loadingSaldo} avatar active>
                <Meta
                  avatar={
                    user.urlImageProfile ? (
                      <Avatar size="large" src={user.urlImageProfile} />
                    ) : (
                      <Avatar size="large" src={NoImage} />
                    )
                  }
                  title={user && user.Nome && user.Nome?.split(' ')[0]}
                  description={
                    <>
                      <div style={{ fontSize: 20 }}>Seu saldo atual é de:</div>
                      {saldo && (
                        <div style={{ fontSize: 24, fontWeight: 'bold' }}>
                          {toCurrency(saldo?.carteira?.saldo) || 0}
                        </div>
                      )}
                    </>
                  }
                />
              </Skeleton>
            </Card>
          </div>
        </Col>

        <Col sm={{ order: 1, span: 10 }} xs={{ order: 2, span: 24 }}>
          <Button
            type="dashed"
            key="home"
            onClick={() => {
              history.push('add-balance');
            }}
            style={{
              textTransform: 'uppercase',
              width: '100%',
              height: '100%',
              borderRadius: 4,
            }}
            size="large"
          >
            <ButtonContent>
              <FaBarcode size={42} />
              <b>&nbsp;&nbsp;Adicionar Saldo</b>
            </ButtonContent>
          </Button>
        </Col>
      </Row>

      <List
        // loading={loadingExtrato}
        itemLayout="horizontal"
        dataSource={bankSlips}
        locale={{
          emptyText: <Empty description={`Nenhum transação encontrada`} />,
        }}
        renderItem={item => (
          <List.Item
            style={{
              backgroundColor: 'white',
              padding: 10,
            }}
          >
            <Skeleton avatar title={false} loading={loadingExtrato} active>
              <List.Item.Meta
                avatar={
                  <Data>
                    <div
                      style={{
                        color: 'white',
                        fontSize: 18,
                        fontWeight: 'bold',
                      }}
                    >
                      {item.dataGeracao.substr(0, 5)}
                    </div>
                    <div style={{ color: 'white', fontSize: 14 }}>
                      {item.dataGeracao.substr(-5)}
                    </div>
                  </Data>
                }
                title={
                  <h3 style={{ color: theme.corPrimaria }}>SALDO POR BOLETO</h3>
                }
                description={
                  <>
                    <div>
                      Vencimento: <b>{item.dataVencimento}</b>
                    </div>
                    <div>
                      Situação: <b>{renderSituacao(item.situacao || '')}</b>
                    </div>
                  </>
                }
              />
              <div>
                <h3 style={{ textAlign: 'right' }}>{toCurrency(item.valor)}</h3>
                <div>Linha digitável</div>
                <LinhaDigitavel linhaDigitavel={item.linhaDigitavel} />
              </div>
            </Skeleton>
          </List.Item>
        )}
      />
      <Pagination
        style={{ textAlign: 'center', marginTop: 20 }}
        onChange={handlePageChange}
        current={page}
        total={total}
        pageSize={5}
        responsive
      />
    </>
  );
};

export default ListBankSlip;
