import React, { useCallback, useState } from 'react';
import { Button, PageHeader, Radio } from 'antd';
import { Container } from './styles';
import { ProdutoConfigType } from 'model/config/ProdutoConfig';
import { ReservaType } from 'model/Reserva';
import TicketChance from '../TicketChance';
import { RadioChangeEvent } from 'antd/lib/radio';
import { onlyNumbers } from 'utils/StringHelper';
import api from 'services/api';
import { useAuth } from 'hooks/auth';
import { useTheme } from 'styled-components';

interface DezenasProps {
  id: string;
  value: string;
}

interface TicketOptionProps {
  product: ProdutoConfigType;
  option: ReservaType;
  onBack: () => void;
  onSelect: (option: ReservaType) => void;
  digitos?: string;
  dezenas?: DezenasProps[];
}

const TicketOption: React.FC<TicketOptionProps> = ({
  onBack,
  product,
  option,
  digitos,
  dezenas,
  onSelect,
}) => {
  const [options, setOptions] = useState([option]);
  const [selected, setSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const { authorization } = useAuth();
  const theme = useTheme();

  const loadTickets = useCallback(
    async (value): Promise<void> => {
      setLoading(true);
      let data = {};
      if (digitos && digitos !== '') {
        data = { codProduto: product.codigo, digitos: onlyNumbers(digitos) };
      } else if (dezenas) {
        data = {
          codProduto: product.codigo,
          dezenas: dezenas.map(d => d.value),
        };
      }
      const response = await api.post(
        '/certificadoDeContribuicao/reserva',
        data,
        {
          headers: { authorization },
        },
      );

      if (response && response.data) {
        setOptions([...options, response.data]);
        setSelected(value);
      }
      setLoading(false);
    },
    [authorization, dezenas, digitos, options, product.codigo],
  );

  const handleChange = (e: RadioChangeEvent) => {
    const { value } = e.target;
    if (!options[value]) {
      loadTickets(value);
    } else {
      setSelected(value);
    }
  };

  // const handleSelect = () => {};

  return (
    <>
      <PageHeader
        onBack={onBack}
        title="Voltar"
        style={{ padding: 0, margin: 0 }}
      />
      <Container>
        <h2 style={{ textAlign: 'center' }}>
          Selecione uma das opções que buscamos
        </h2>
        <Radio.Group
          defaultValue={0}
          buttonStyle="solid"
          onChange={handleChange}
          size="small"
        >
          <Radio.Button value={0}>OPÇÃO 1</Radio.Button>
          <Radio.Button disabled={options.length === 0} value={1}>
            OPÇÃO 2
          </Radio.Button>
          <Radio.Button disabled={options.length === 1} value={2}>
            OPÇÃO 3
          </Radio.Button>
        </Radio.Group>
      </Container>
      {options && options[selected] && (
        <>
          <TicketChance option={options[selected]} product={product} />
          <Container>
            <Button
              onClick={() => onSelect(options[selected])}
              type="primary"
              loading={loading}
              style={{
                borderRadius: 10,
                borderColor: 'transparent',
                backgroundColor: theme.corPrimaria,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: 20,
                padding: '24px 40px',
              }}
            >
              QUERO ESSE
            </Button>
          </Container>
        </>
      )}
    </>
  );
};

export default TicketOption;
