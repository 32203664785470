/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, List, message, PageHeader, Popconfirm, Skeleton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAuth } from 'hooks/auth';
import api from 'services/api';
import { Container } from './styles';
import { FaUserFriends } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { AmigoType } from 'model/Amigo';

const Friends: React.FC = () => {
  const { authorization } = useAuth();
  const [friends, setFriends] = useState<AmigoType[]>([]);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const history = useHistory();

  const loadAmigos = useCallback(async (): Promise<void> => {
    setLoading(true);
    const response = await api.get('/amigo/lista', {
      headers: { authorization },
    });

    if (response && response.data) {
      setFriends(response.data);
    }
    setLoading(false);
  }, [authorization]);

  useEffect(() => {
    loadAmigos();
  }, [loadAmigos]);

  const handleDelete = async (id: number): Promise<void> => {
    setLoading(true);
    const response = await api.post(
      '/amigo/excluir',
      { id },
      {
        headers: { authorization },
      },
    );

    if (response && response.data) {
      message.success('Amigo excluído com sucesso');
      loadAmigos();
    }
    setLoading(false);
  };

  const handleNew = () => {
    history.push('/friend');
  };

  return (
    <Container>
      <PageHeader
        backIcon={false}
        title={
          <>
            <h2 style={{ color: theme.corPrimaria }}>
              <FaUserFriends size={20} /> Meus Amigos
            </h2>
          </>
        }
        extra={[
          <Button
            onClick={handleNew}
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
          >
            Novo
          </Button>,
        ]}
      />
      {friends && (
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={friends}
          renderItem={item => (
            <List.Item
              key={item.id}
              style={{
                backgroundColor: 'white',
                padding: 10,
                textAlign: 'left',
              }}
              actions={[
                <Popconfirm
                  title="Deseja Realmente excluir ?"
                  onConfirm={() => handleDelete(item.id)}
                  onCancel={() => null}
                  okText="Sim"
                  cancelText="Não"
                >
                  <a href="#">Excluir</a>
                </Popconfirm>,
              ]}
            >
              <Skeleton title={false} loading={loading} active>
                <List.Item.Meta
                  title={item.nomeCompleto}
                  description={item.cpf}
                />
              </Skeleton>
            </List.Item>
          )}
        />
      )}
    </Container>
  );
};

export default Friends;
