/* eslint-disable no-template-curly-in-string */
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  Col,
  InputNumber,
  message,
  notification,
  PageHeader,
  Row,
  Spin,
  Steps,
} from 'antd';
import { useTheme } from 'styled-components';
import { FaChevronLeft, FaChevronRight, FaUser } from 'react-icons/fa';
import { Form, Input, Button, Select } from 'antd';
import { Content } from './styles';
import ButtonGroup from 'antd/lib/button/button-group';
import api from 'services/api';
import { useAuth } from 'hooks/auth';
import { UsuarioType } from 'model/Usuario';
import brazilStates from 'utils/brazilStates';
import { onlyNumbers } from 'utils/StringHelper';
import { CepType } from 'model/Cep';
import MaskedInput from 'antd-mask-input/build/main/lib/MaskedInput';
import formatCpf from '@brazilian-utils/format-cpf';
import md5 from 'md5';

const { Option } = Select;

const tailLayout = {
  wrapperCol: { sm: { offset: 8, span: 8 }, xs: { span: 24 } },
};

const { Step } = Steps;

const steps = [
  {
    title: 'Meus Dados',
    description:
      'Alguns dados não podem ser alterados. Caso tenha necessidade entre em contato com o nosso atendimento.',
  },
  {
    title: 'Dados de contato',
    description: (
      <span>
        Com seus dados para contado,{' '}
        <b>garantimos sua segurança e sucesso na entrega dos prêmios,</b> caso
        você seja um dos ganhadores.
      </span>
    ),
  },
  {
    title: 'Alterar Senha',
    description: 'Digite os dados abaixo se quiser alterar sua senha.',
  },
];

const validateMessages = {
  required: '${label} é obrigatório!',
  types: {
    number: '${label} não é um número válido!',
    email: '${label} não é um e-mail válido!',
  },
  senhaAtual: {
    pattern: '${label} a senha dever conter 4 números!',
  },
};

const UserData: React.FC = () => {
  const [current, setCurrent] = useState(0);

  const next = async () => {
    try {
      await form.validateFields();
      if (current === steps.length - 1) {
        form.submit();
      } else {
        setCurrent(current + 1);
      }
    } catch (error) {}
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const theme = useTheme();
  const [form] = Form.useForm();
  const { authorization } = useAuth();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [person, setPerson] = useState<UsuarioType>();
  const [cep, setCep] = useState<CepType>();
  const [newPassword, setNewPassword] = useState('');

  const sendPost = useCallback(
    async (user: any): Promise<void> => {
      setSaving(true);
      const response = await api.post('/usuario/atualizar', user, {
        headers: { authorization },
      });
      if (response && response.data) {
        notification.success({
          message: response.data.mensagem,
          description: '🎉 Parabéns!',
        });
        setCurrent(0);
      }
      setSaving(false);
    },
    [authorization],
  );

  const onFinish = async (values: any) => {
    const user = { ...values };
    if (newPassword) {
      user.senha = md5(values.novaSenha);
      user.senhaAtual = md5(values.senhaAtual);
    }
    user.ddd = onlyNumbers(user.telefone).substr(0, 2);
    user.celular = onlyNumbers(user.telefone).substr(2);
    if (!user.email) delete user.email;
    if (!user.complemento) delete user.complemento;
    delete user.cpf;
    delete user.telefone;
    if (!user.senhaAtual) {
      delete user.senhaAtual;
      delete user.confirmacaoSenha;
      delete user.novaSenha;
    }
    await sendPost(user);
  };

  const handleNext = () => {
    next();
  };

  const loadData = useCallback(async (): Promise<void> => {
    setLoading(true);
    const response = await api.get(`/usuario/meusDados`, {
      headers: { authorization },
    });
    if (response && response.data) {
      setPerson(response.data);
    }
    setLoading(false);
  }, [authorization]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const isValidCep = (cepValue: string): boolean => {
    return onlyNumbers(cepValue).length === 8;
  };

  const handleCep = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (isValidCep(value)) {
      api
        .get(`/consultarCep?cep=${value}`)
        .then(response => {
          if (response && response.data) {
            setCep(response.data);
          }
          form.setFields([
            { name: 'logradouro', value: response.data.logradouro },
            { name: 'estado', value: response.data.estado },
            { name: 'cidade', value: response.data.cidade },
            { name: 'bairro', value: response.data.bairro },
          ]);
        })
        .catch(error => {
          message.error(error.response.data.mensagem);
        });
    } else {
      setCep(undefined);
    }
  };

  const handleNewPassword = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setNewPassword(value);
  };

  const checkPasswords = (rule: any, value: any) => {
    if (
      (newPassword && value === newPassword) ||
      current !== 2 ||
      !newPassword
    ) {
      return Promise.resolve();
    }
    return Promise.reject('As senhas não conferem');
  };

  return (
    <>
      <PageHeader
        backIcon={false}
        title={
          <h3 style={{ color: theme.corPrimaria }}>
            <FaUser size={20} /> Meus dados e senha
          </h3>
        }
      />
      {loading && <Spin size="large" />}
      {!loading && person && (
        <Content>
          <Steps current={current} direction="vertical">
            {steps.map((item, index) => (
              <Step
                key={item.title}
                style={{ display: current !== index ? 'none' : 'block' }}
                title={item.title}
                description={item.description}
              />
            ))}
          </Steps>

          <Form
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            onSubmitCapture={onFinish}
            layout="vertical"
            initialValues={{
              ...person,
              telefone: person.ddd + person.celular,
              cpf: formatCpf(person.cpf),
            }}
            validateMessages={validateMessages}
          >
            <div hidden={current !== 0}>
              <Form.Item name="cpf" label="CPF" rules={[{ required: true }]}>
                <Input size="large" readOnly disabled />
              </Form.Item>
              <Form.Item
                name="nomeCompleto"
                label="Nome Completo"
                rules={[{ required: true }]}
              >
                <Input readOnly disabled />
              </Form.Item>
              <Form.Item
                name="dataNascimento"
                label="Data de Nascimento"
                rules={[{ required: true }]}
              >
                <Input readOnly disabled />
              </Form.Item>
              <Form.Item name="sexo" label="Sexo" rules={[{ required: true }]}>
                <Select placeholder="Selecione seu Sexo" allowClear disabled>
                  <Option value="M">Masculino</Option>
                  <Option value="F">Feminino</Option>
                  <Option value="O">Outros</Option>
                </Select>
              </Form.Item>
            </div>

            <div hidden={current !== 1}>
              <Form.Item
                name="email"
                label="E-mail"
                rules={[{ required: current === 1, type: 'email' }]}
              >
                <Input type="email" />
              </Form.Item>
              <Row>
                <Col sm={{ span: 10 }} xs={24}>
                  <Form.Item
                    name="telefone"
                    label="Nº Telefone com DDD"
                    rules={[{ required: current === 1 }]}
                  >
                    <MaskedInput mask="(11) 11111-1111" />
                  </Form.Item>
                </Col>
                <Col sm={{ span: 10, offset: 4 }} xs={24}>
                  <Form.Item
                    name="cep"
                    label="CEP"
                    rules={[{ required: current === 1 }]}
                  >
                    <MaskedInput onChange={handleCep} mask="11111-111" />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="logradouro"
                label="Rua"
                rules={[{ required: current === 1 }]}
              >
                <Input disabled={!!(cep && cep.logradouro)} />
              </Form.Item>

              <Row>
                <Col sm={{ span: 8 }} xs={24}>
                  <Form.Item
                    name="numero"
                    label="Número"
                    rules={[{ required: current === 1 }]}
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                </Col>
                <Col sm={{ span: 12, offset: 4 }} xs={24}>
                  <Form.Item name="complemento" label="Complemento">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="bairro"
                label="Bairro"
                rules={[{ required: current === 1 }]}
              >
                <Input disabled={!!(cep && cep.bairro)} />
              </Form.Item>

              <Row>
                <Col sm={{ span: 8 }} xs={24}>
                  <Form.Item
                    name="estado"
                    label="UF"
                    rules={[{ required: current === 1 }]}
                  >
                    <Select
                      placeholder="Selecione"
                      allowClear
                      disabled={!!(cep && cep.estado)}
                    >
                      {brazilStates.map(uf => (
                        <Option key={uf.sigla} value={uf.sigla}>
                          {uf.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={{ span: 12, offset: 4 }} xs={24}>
                  <Form.Item
                    name="cidade"
                    label="Cidade"
                    rules={[{ required: current === 1 }]}
                  >
                    <Input disabled={!!(cep && cep.cidade)} />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div hidden={current !== 2}>
              <Row>
                <Col sm={{ span: 8 }} xs={24}>
                  <Form.Item
                    name="senhaAtual"
                    label="Senha Atual"
                    rules={[
                      {
                        required: !!(current === 2 && newPassword),
                      },
                      {
                        pattern: new RegExp(/\d\d\d\d/g),
                        message: 'A senha deve conter 4 números!',
                      },
                    ]}
                  >
                    <Input.Password size="large" max={9999} maxLength={4} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm={{ span: 8 }} xs={24}>
                  <Form.Item
                    name="novaSenha"
                    label="Digite uma Senha de 4 dígitos"
                    rules={[
                      {
                        pattern: new RegExp(/\d\d\d\d/g),
                        message: 'A senha deve conter 4 números!',
                      },
                    ]}
                  >
                    <Input.Password
                      onChange={handleNewPassword}
                      size="large"
                      max={9999}
                      maxLength={4}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm={{ span: 8 }} xs={24}>
                  <Form.Item
                    name="confirmacaoSenha"
                    label="Confirme sua Senha de 4 dígitos"
                    rules={[
                      {
                        required: !!(current === 2 && newPassword),
                        validator: checkPasswords,
                      },
                      {
                        pattern: new RegExp(/\d\d\d\d/g),
                        message: 'A senha deve conter 4 números!',
                      },
                    ]}
                  >
                    <Input.Password size="large" max={9999} maxLength={4} />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <Form.Item {...tailLayout} style={{ textAlign: 'center' }}>
              <ButtonGroup size="large" style={{ textAlign: 'center' }}>
                <Button
                  disabled={current === 0 || saving}
                  style={{ margin: '0 8px' }}
                  onClick={() => prev()}
                  size="large"
                >
                  <FaChevronLeft />
                  &nbsp;Voltar
                </Button>
                <Button
                  disabled={
                    current === steps.length - 1 &&
                    !!form.getFieldsError().find(f => f.errors.length > 0)
                  }
                  type="primary"
                  onClick={handleNext}
                  size="large"
                  loading={saving}
                >
                  {current === steps.length - 1 ? 'Salvar' : 'Próximo'}&nbsp;
                  <FaChevronRight />
                </Button>
              </ButtonGroup>
            </Form.Item>
          </Form>
        </Content>
      )}
    </>
  );
};

export default UserData;
