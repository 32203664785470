/* eslint-disable no-template-curly-in-string */
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  PageHeader,
  Row,
  Select,
  Spin,
} from 'antd';
import React, { useState } from 'react';
import Cards from 'react-credit-cards';
import { format } from 'date-fns';
import { FaCreditCard } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import usePaymentMethod from './usePaymentMethod';
const { Option } = Select;

const validateMessages = {
  required: '${label} é obrigatório!',
  types: {
    number: '${label} não é um número válido!',
  },
};

const INITIAL_CARD_STATE = {
  focus: '',
  cvc: '',
  expiry: '',
  name: '',
  number: '',
  issuer: '',
};

interface PaymentMethodProps {
  isModal?: boolean;
  onSave?: (id: number) => void;
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({ isModal, onSave }) => {
  const theme = useTheme();
  const [form] = Form.useForm();
  const { saveCard, loading: loadingSave } = usePaymentMethod();
  const [maxLength, setMaxLength] = useState<number>(16);
  const [state, setState] = useState<any>(INITIAL_CARD_STATE);

  const handleInputFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, focus: e.target.name });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleInputChangeNumber = (value: string | number | undefined) => {
    if (value) setState({ ...state, number: value });
  };

  const onFinish = async (values: any) => {
    const id = await saveCard({ ...state, tipoCartao: values.card.tipoCartao });
    if (id) {
      message.success('Cartão inserido com sucesso');
      form.resetFields();
      setState(INITIAL_CARD_STATE);
      if (onSave) {
        onSave(id);
      }
    }
  };

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return (
      current && format(current._d, 'yyyyMM') < format(new Date(), 'yyyyMM')
    );
  };

  const handleExpiryChange = (e: any) => {
    if (e) {
      const data = format(e._d, 'MM/yyyy');
      setState({ ...state, expiry: data });
    }
  };

  const callback = (data: any) => {
    setMaxLength(data.maxLength);
    if (data.issuer !== 'unknown') {
      setState({ ...state, issuer: data.issuer });
    }
  };

  return (
    <>
      {!isModal && (
        <PageHeader
          backIcon={false}
          title={
            <>
              <h2 style={{ color: theme.corPrimaria }}>
                <FaCreditCard size={20} /> Novo Cartão
              </h2>
            </>
          }
        />
      )}
      {loadingSave && <Spin size="large" />}

      <Row style={{ backgroundColor: 'white', padding: 10 }}>
        <Col xs={24} md={isModal ? 24 : 12} style={{ margin: 'auto' }}>
          <Cards
            cvc={state.cvc}
            expiry={state.expiry}
            focused={state.focus}
            name={state.name}
            number={state.number}
            callback={callback}
            placeholders={{ name: 'NOME DO TITULAR' }}
          />
        </Col>
        <Col xs={24} md={isModal ? 24 : 12}>
          <Form
            layout="vertical"
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
            style={{ marginTop: 20 }}
            form={form}
          >
            <Form.Item
              name={['card', 'number']}
              label="Número do Cartão"
              rules={[{ required: true, type: 'number' }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                type="tel"
                name="number"
                placeholder="Número do Cartão"
                onChange={handleInputChangeNumber}
                onFocus={handleInputFocus}
                maxLength={maxLength}
                className="no-show-button"
              />
            </Form.Item>
            <Form.Item
              name={['card', 'name']}
              label="Nome do Titular"
              rules={[{ required: true }]}
            >
              <Input
                name="name"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                maxLength={30}
              />
            </Form.Item>
            <Form.Item
              name={['card', 'expiry']}
              label="Validade"
              rules={[{ required: true }]}
            >
              <DatePicker
                picker="month"
                disabledDate={disabledDate}
                onChange={handleExpiryChange}
                onFocus={() => setState({ ...state, focus: 'expiry' })}
                format="MM/YY"
              />
            </Form.Item>
            <Form.Item
              name={['card', 'tipoCartao']}
              label="Tipo do Cartão"
              rules={[{ required: true }]}
            >
              <Select placeholder="Selecione o tipo do cartão">
                <Option value="1">Débito</Option>
                <Option value="2">Crédito</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button disabled={loadingSave} type="primary" htmlType="submit">
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default PaymentMethod;
