import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'antd';
import { ProdutoConfigType } from 'model/config/ProdutoConfig';
import { toCurrency } from 'utils/StringHelper';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import {
  ContainerHeader,
  ContainerTotal,
  BoxTotal,
  LabelTotal,
  Total,
  BoxQuantity,
  QuantityTotal,
} from './styles';
import { ReservaType } from 'model/Reserva';

interface TotalsProps {
  product: ProdutoConfigType;
  setQuantity: (qty: number) => void;
  reservas: ReservaType[];
  cancelReservas: () => void;
}

const Totals: React.FC<TotalsProps> = ({
  product,
  setQuantity,
  reservas,
  cancelReservas,
}) => {
  const [qty, setQty] = useState(1);

  const handleMinus = useCallback((): void => {
    if (qty > 1) {
      setQty(prev => prev - 1);
    }
    if (reservas.length > 0) cancelReservas();
  }, [qty, reservas, cancelReservas]);

  const handlePlus = useCallback((): void => {
    setQty(prev => prev + 1);
  }, []);

  useEffect(() => {
    setQuantity(qty);
  }, [qty, setQuantity]);

  return product ? (
    <ContainerHeader>
      <ContainerTotal colors={product.config?.web}>
        {product.promocao?.valorProduto && (
          <BoxTotal>
            <LabelTotal>Total</LabelTotal>
            <Total>
              {toCurrency(String(product.promocao?.valorProduto * qty))}
            </Total>
          </BoxTotal>
        )}

        <BoxQuantity>
          <Button
            shape="circle"
            icon={<MinusOutlined />}
            size="large"
            onClick={handleMinus}
          />

          <QuantityTotal>{qty}</QuantityTotal>

          <Button
            shape="circle"
            icon={<PlusOutlined />}
            size="large"
            onClick={handlePlus}
          />
        </BoxQuantity>
      </ContainerTotal>
    </ContainerHeader>
  ) : (
    <></>
  );
};

export default Totals;
