import React from 'react';

import { ConfigProvider } from './config';
import { AuthProvider } from './auth';

const AppProvider: React.FC = ({ children }) => (
  <ConfigProvider>
    <AuthProvider>{children}</AuthProvider>
  </ConfigProvider>
);

export default AppProvider;
