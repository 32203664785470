import React, { useState, useCallback } from 'react';

import { useAuth } from 'hooks/auth';
import { BoletoType } from 'model/Boleto';
import api from 'services/api';

import ResultBankSlip from './ResultBankSlip';
import {
  Container,
  Boxes,
  Box,
  BoxText,
  BoxTextCurrency,
  BoxContent,
} from './styles';
import { MaskedInput } from 'antd-mask-input';
import { FaBarcode } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import { Button, PageHeader } from 'antd';
import { useConfig } from 'hooks/config';

const AddBankSlip: React.FC = () => {
  const [boxSelected, setBoxSelected] = useState('');
  const [bankSlipValue, setBankSlipValue] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [boleto, setBoleto] = useState<BoletoType>();

  const [loading, setLoading] = useState(false);
  const { authorization } = useAuth();

  const theme = useTheme();

  const { product } = useConfig();

  const handleSubmit = useCallback(async (): Promise<void> => {
    let bsValue = 0;
    switch (boxSelected) {
      case '1':
        bsValue = 50;
        break;
      case '2':
        bsValue = 100;
        break;
      case '3':
        bsValue = 150;
        break;
      default:
        bsValue = bankSlipValue;
    }
    setLoading(true);
    const response = await api.post(
      '/boleto/gerar',
      {
        codProduto: (product && product.codigo) || 'apcapsp',
        tipo: 'saldo',
        valor: bsValue,
      },
      { headers: { authorization } },
    );
    if (response && response.data) {
      setBoleto(response.data);
      setShowResult(true);
    }
    setLoading(false);
  }, [bankSlipValue, boxSelected, authorization, product]);

  return (
    <>
      <Container>
        <PageHeader
          backIcon={false}
          title={
            <h3 style={{ color: theme.corPrimaria }}>
              <FaBarcode size={20} /> CARTEIRA APCAP
            </h3>
          }
        />

        <Boxes title="Selecione o valor que deseja adicionar">
          <Box active={boxSelected === '1'}>
            <BoxContent onClick={() => setBoxSelected('1')}>
              <BoxTextCurrency active={boxSelected === '1'}>R$</BoxTextCurrency>
              <BoxText active={boxSelected === '1'}>
                50,
                <BoxTextCurrency active={boxSelected === '1'}>
                  00
                </BoxTextCurrency>
              </BoxText>
            </BoxContent>
          </Box>
          <Box active={boxSelected === '2'}>
            <BoxContent onClick={() => setBoxSelected('2')}>
              <BoxTextCurrency active={boxSelected === '2'}>R$</BoxTextCurrency>
              <BoxText active={boxSelected === '2'}>
                100,
                <BoxTextCurrency active={boxSelected === '2'}>
                  00
                </BoxTextCurrency>
              </BoxText>
            </BoxContent>
          </Box>
        </Boxes>

        <Boxes>
          <Box active={boxSelected === '3'}>
            <BoxContent onClick={() => setBoxSelected('3')}>
              <BoxTextCurrency active={boxSelected === '3'}>R$</BoxTextCurrency>
              <BoxText active={boxSelected === '3'}>
                150,
                <BoxTextCurrency active={boxSelected === '3'}>
                  00
                </BoxTextCurrency>
              </BoxText>
            </BoxContent>
          </Box>
          <Box active={boxSelected === '4'}>
            <BoxContent onClick={() => setBoxSelected('4')}>
              {/* <BoxTextCurrency active={boxSelected === '4'}>
                <b>Outro</b>
              </BoxTextCurrency> */}
              <BoxTextCurrency active={boxSelected === '4'}>R$</BoxTextCurrency>
              <MaskedInput
                mask="1111"
                placeholderChar=" "
                style={{
                  fontSize: 30,
                  fontWeight: 'bold',
                  width: 86,
                  border: 0,
                  color: theme.corPrimaria,
                  padding: 0,
                  textAlign: 'center',
                }}
                name="outroValor"
                onChange={e =>
                  setBankSlipValue(Number.parseFloat(e.target.value))
                }
                onTouchEnd={() => setBoxSelected('4')}
              />
            </BoxContent>
          </Box>
        </Boxes>
        <br />
        <br />
        <Button
          type="primary"
          size="large"
          style={{ width: '100%' }}
          disabled={!boxSelected || (boxSelected === '4' && !bankSlipValue)}
          loading={loading}
          onClick={handleSubmit}
        >
          Gerar Boleto
        </Button>
      </Container>
      {boleto && (
        <ResultBankSlip
          boleto={boleto}
          showResult={showResult}
          setShowResult={setShowResult}
        />
      )}
    </>
  );
};

export default AddBankSlip;
