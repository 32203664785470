import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;

  && .ant-select-selector,
  .ant-select-single {
    height: 50px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const ItemOption = styled.div`
  span {
    margin-right: 6px;
  }
`;

export const NewCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  div {
    margin-left: 16px;
    color: green;
    font-weight: bold;
  }
`;

export const AddBalance = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;

export const Saldo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  div {
    margin-left: 16px;
    color: ${props => props.theme.corPrimaria};
    font-weight: bold;
  }
`;
