import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
`;

export const ItemOption = styled.div`
  span {
    margin-right: 6px;
  }
`;
